//typy odstavcu: 0 - text (text), 1 - obrazek (src, alt), 2 - tabulka (tr - td)
//{id: 1, typ: 0, text: ""}
//{id: 1, typ: 1, src: "", alt: ""}
//{id: 1, typ: 2, tr [{id: 1, td: [{data:}]}]}

const problemy = [
    { 
        id: 0,
        hrefid: "",
        nazev: "Úvodní slovo k seriálu",
        nadpis: "Přístup k řešení problémů může být různý. Ať už se jedná o problémy osobní, pracovní nebo jakékoliv jiné. Velmi specifickou oblastí potom je řešení problémů politických. A je úplně jedno, na jaké úrovni se pohybujeme.",
        odstavce: [
            {id: 1, typ: 0, text: "Naše volební strana **Hořický STAN** vstupuje v Hořicích do komunálních voleb 2022 s předsevzetím, že chce řešit problémy, které reálně existují, trápí město, ale v Hořicích se o nich nemluví. Nechceme následovat řadu hořických zastupitelů a městských hodnostářů, kteří již dlouhá léta dělají pštrosí politiku a s hlavou v písku nevidí, že s blížící se dálnicí městu ujíždí pověstný vlak."},
            {id: 2, typ: 0, text: "Hořická veřejnost, bohužel, nemá ucelené informace o skutečném stavu města. Na křídovém papíře oficiálně šířené informace popisují stav města v jásavých barvách a slibují světlé zítřky. Naše volební **Hořický STAN** je však přesvědčena, že problémy, o kterých se nemluví, ohrožují budoucnost města, jeho rozvoj a proto je třeba je veřejně a nahlas pojmenovat a navrhnout jejich řešení."},
            {id: 3, typ: 0, text: "To jsou důvody, proč vám, občanům Hořic, v několika pokračováních seriálu Problémy o kterých se nemluví, předložíme fakta o skutečném stavu města. Nepůjde o nějaké domněnky nebo spekulace, ale o data z oficiálních dokumentů města anebo o nezpochybnitelná data statistiků."}, 
            {id: 4, typ: 0, text: "_Zdroje informací:_ \n - _Český statistický úřad_ \n - _Závěrečné účty města Hořic_ \n - _Hořický zpravodaj 5/2022_ \n - _Seznam hořických domů, stav k září 1927, sestavili K. Novotný a J. Řehák_"}
       ],
        scroll: false
    },
    {
        id: 1,
        hrefid: "problem/rozkvet-upadek",
        nazev: "Období rozkvětu a úpadku",
        nadpis: "Za posledních 30 let ubylo v našem městě 1 097 obyvatel a Hořice se blíží okamžiku, kdy počet obyvatel města klesne pod 8 000.",
        odstavce: [
            {id: 1, typ: 0, text: "V historii každého města jsou období rozkvětu, ale i úpadku a Hořice nejsou výjimkou. Jistě je více kritérií, podle kterých je možné hodnotit, jestli se město rozvíjí, anebo upadá. Ale tím nejzákladnějším údajem nesporně je počet obyvatel města. Především to, jestli počet obyvatel roste, anebo naopak.\nV databázích Českého statistického úřadu jsou k dispozici výsledky sčítání lidu od roku 1869 až do loňského roku. "},
            {id: 2, typ: 3, src: "problemy/dil1-t.webp"},
            /*{id: 2, typ: 2, tr: [
                {id: 1, td: [
                    {data: 1869},
                    {data: 1880},
                    {data: 1890},
                    {data: 1900},
                    {data: 1910},
                    {data: 1921},
                    {data: 1930},
                    {data: 1950},
                    {data: 1961},
                    {data: 1970},
                    {data: 1980},
                    {data: 1991},
                    {data: 2001},
                    {data: 2011},
                    {data: 2021},
                ]
            },
                {id: 2, td: [
                    {data: 6710},
                    {data: 7028},
                    {data: 7894},
                    {data: 8814},
                    {data: 9897},
                    {data: 9787},
                    {data: 9421},
                    {data: 7700},
                    {data: 7754},
                    {data: 8090},
                    {data: 9251},
                    {data: 9282},
                    {data: 9091},
                    {data: 8783},
                    {data: 8185},
                ]}
            ]},*/
            {id: 3, typ: 0, text: "Více než tabulka ale řekne graf."},
            {id: 4, typ: 1, src: "problemy/pobyvatel.webp", alt: "Graf vývoje počtu obyvatel Hořic"},
            {id: 5, typ: 0, text: "Za posledních více než 150 let měly Hořice nejvíce obyvatel v letech 1910 a 1991. V těchto letech vrcholila desetiletí trvající období rozvoje města, během kterých počet obyvatel trvale rostl. Za 41 let 1869 až 1910 (za Rakouska) se Hořice rozrostly o 2869 obyvatel a k překročení hranice 10 000 jim chybělo 103 obyvatel. Ve stejně dlouhém období (většinou reálného socialismu) 1950 až 1991 přibylo v Hořicích 1580 obyvatel. Tento nárůst počtu obyvatel z větší části nahradil úbytek obyvatel způsobený dvěma světovými válkami a s tím souvisejícím rozvratem hospodářství. V tomto smyslu se dá o obou těchto obdobích hovořit jako o obdobích rozkvětu města."},
            {id: 6, typ: 0, text: "Úpadek města po roce 1910, kdy se počet jeho obyvatel postupně snížil o 2 197, byl způsoben vnějšími geopolitickými okolnostmi dvou světových válek. Na to nemělo město a jeho vedení žádný vliv. Naopak v třicetiletém období 1991 až 2021, kdy se počet obyvatel snížil o 1 097, nepůsobily na život města žádné vnější zásadně negativní geopolitické vlivy. Úpadek města v tomto období proto padá na vrub většiny vedení města v posledních třiceti letech, i když ne na všechna vedení radnice stejně. K výraznému urychlení úpadku města totiž dochází právě nyní v několika posledních letech a je velmi pravděpodobné, že se Hořice rychle a nezadržitelně blíží k poklesu počtu obyvatel pod hranici 8 000."}
        ],
        scroll: true
    },
    { 
        id: 2,
        hrefid: "problem/upadek-mesta",
        nazev: "Úpadek města třicet let po sametu",
        nadpis: "V posledním desetiletí 2011 až 2021 ubyl v Hořicích stejný počet obyvatel, jako v předcházejících dvaceti letech dohromady.",
        odstavce: [
            {id: 1, typ: 0, text: "V minulém dílu seriálu jsme se zabývali obdobími rozkvětu a úpadku města v minulých 150 letech. V této části se zaměříme na období třiceti let po sametové revoluci."},
            {id: 2, typ: 0, text: "Podrobná data o vývoji počtu obyvatel Hořic (a všech obcí ČR) je publikován Českým statistickým úřadem v Databázi demografických údajů za vybraná města ČR. Podle této databáze ubylo v Hořicích v letech 1991 až 2021 celkem 1039 obyvatel. Nepodstatný rozdíl mezi údaji Sčítání lidu (1097) a Databáze demografických údajů (1039) je dán tím, že údaje databáze jsou k poslednímu prosinci příslušného roku a sčítání lidu se zpravidla provádějí v polovině kalendářního roku. Přehlednější než dlouhá tabulka je graf vývoje počtu obyvatel Hořic v letech 1991 až 2021. "},
            {id: 3, typ: 1, src: "problemy/pobyvatel-10let.webp", alt: "Graf vývoje počtu obyvatel Hořic"},
            {id: 4, typ: 0, text: "Z grafu je zřetelná tendence trvalého úbytku obyvatel města během posledních třiceti let i to, že tento vývoj v poslední dekádě výrazně zrychlil. Ukazují to úbytky obyvatel vypočítané jednotlivě za každé desetiletí."},
            //{id: 5, typ: 2, tr: [{id: 1, td: [{data: "desetiletí", b:true}, {data: "úbytek obvatel", b:true}]}, {id: 2, td: [{data: "1991 - 2001"}, {data: -227}]}, {id: 3, td: [{data: "2001-2011"}, {data: -281}]},{id: 4, td: [{data: "2011-2021"}, {data: -531}]},{id: 5, b: true, td: [{data: "Celkem", b:true}, {data: -1039, b:true}]}]},
            {id: 5, typ: 3, src: "problemy/dil2-t.webp", md:true},
            {id: 6, typ: 0, text: "Zrychlující tendence úbytku obyvatel města je zřejmá. Je také jasné, že v posledním desetiletí 2011 až 2021 ubylo v Hořicích obyvatel stejně, jako v předcházejících dvaceti letech dohromady. Tento vývoj počtu obyvatel má negativní dopady nejen do věkového složení obyvatel, ale má návaznost i na výši dotací státu do městského rozpočtu. A tyto dopady se počítají v řádu milionů korun."},
        ],
        scroll: true
    },
    { 
        id: 3,
        hrefid: "problem/obyvatele-ubyvaji-rychleji-nez-jinde",
        nazev: "V Hořicích ubývají obyvatelé rychleji než jinde",
        nadpis: "V Jaroměři se za posledních třicet let snížil počet obyvatel o 2,6 %, v Hořicích klesl počet obyvatel za stejnou dobu více než čtyřnásobně o 11,2 %.",
        odstavce: [
            {id: 1, typ: 0, text: "Hořice nejsou izolovaným městem, ale žijí v kontextu dalších měst. A proto je namístě vývoj počtu obyvatel porovnat s městy v blízkém okolí. Velikostí i historií je možné za srovnatelné konkurenty považovat Nový Bydžov, Novou Paku a Jaroměř. Výpočet rozdílu počtu obyvatel těchto měst v letech 1991 až 2021 je v následující tabulce zároveň s výpočtem, kolik procent tento rozdíl znamená ve vztahu k počtu obyvatel v roce 1991."},
            //{id: 2, typ: 2, tr: [{id: 1, td: [{data: ""}, {data: "úbytek za 1991-2021", b:true}, {data: "úbytek %", b:true}]}, {id: 2, td: [{data: "Hořice", b:true}, {data: -1039}, {data: 11.2}]}, {id: 3, td: [{data: "Nová Paka", b:true}, {data: -554}, {data: 5.9}]},{id: 4, td: [{data: "Nový Bydžov", b:true}, {data: -246}, {data: 3.4}]},{id: 5, td: [{data: "Jaroměř", b:true}, {data: -333}, {data: 2.6}]}]},
            {id: 2, typ: 3, src:"problemy/dil3-t.webp", md:true},
            {id: 3, typ: 0, text: "Z tabulky je vidět jednoznačný výsledek - úbytek počtu obyvatel Hořic je ve srovnání s městy v blízkém okolí násobně vyšší. Vzhledem k tomu, že se jedná o data za poměrně dlouhé období, je možné vyloučit nahodilou odchylku v trendech vývoje počtu obyvatel těchto čtyř měst. Úbytek obyvatel vykazují všechna čtyři města, ale úbytek obyvatel Hořic je výrazně nejvyšší a z dalších dat (část 2 seriálu) je zřejmá tendence zrychlování tohoto vývoje."},
        ],
        scroll: true
    },
    { 
        id: 4,
        hrefid: "problem/obce-v-okoli-horic",
        nazev: "Na venkově v okolí Hořic obyvatelé přibývají",
        nadpis: "V obcích v okolí Hořic obyvatelé přibývají. Už jich na venkově kolem Hořic žije víc než v Hořicích.",
        odstavce: [
            {id: 1, typ: 0, text: "Srovnávání Hořic s obcemi, které mají řádově méně obyvatel, by mohlo být problematické. Problematičnost ale odstraní vyjádření přírůstku počtu obyvatel obce v procentech a šestnáct let dlouhá datová řada (2006-2021). Vypovídací hodnota výsledků je potom dostatečně významná a nelze nad ní mávnout rukou. Naopak by bylo užitečné zamyslet se nad širšími souvislostmi, které by vysvětlily příčiny rozdílného vývoje počtu obyvatel Hořic a obcí v okolí. V tabulce jsou data za 19 obcí a pro srovnání i Hořice."},
            //{id: 2, typ: 2, tr: [{id: 1, td: [{data: "obec", b:true}, {data: "přírůstek obyvatel", b:true}, {data: "obec", b:true}, {data: "přírůstek obyvatel", b:true}, {data: "obec", b:true}, {data: "přírůstek obyvatel", b:true}, {data: "obec", b:true}, {data: "přírůstek obyvatel", b:true}]}, {id: 2, td: [{data: "Rašín", b:true}, {data: "51%"}, {data: "Bříšťany", b:true}, {data: "16%"},{data: "Milovice", b:true}, {data: "5%"},{data: "Jeřice", b:true}, {data: "1%"}]}, {id: 3, td: [{data: "Konecchlumí", b:true}, {data: "24%"}, {data: "Lískovice", b:true}, {data: "15%"},{data: "Třebnouševes", b:true}, {data: "4%"},{data: "Sobčice", b:true}, {data: "-7%"}]}, {id: 4, td: [{data: "Lukavec", b:true}, {data: "21%"}, {data: "Č. Třemešná", b:true}, {data: "10%"},{data: "D. Voda", b:true}, {data: "4%"},{data: "Chomutice", b:true}, {data: "-5%"}]}, {id: 5, td: [{data: "Boháňka", b:true}, {data: "18%"}, {data: "Sukorady", b:true}, {data: "9%"},{data: "Holovousy", b:true}, {data: "4%"},{data: "Cerekvice", b:true}, {data: "-2%"}]}, {id: 6, td: [{data: "Bílsko", b:true}, {data: "17%"}, {data: "Bašnice", b:true}, {data: "6%"},{data: "Podhor. Újezd", b:true}, {data: "2%"},{data: "HOŘICE", b:true}, {data: "-11,2%", b:true}]}]},
            {id: 2, typ: 3, src: "problemy/dil4-t.webp"},
            {id: 3, typ: 0, text: "K rozšíření pohledu na úbytek počtu obyvatel Hořic je užitečné doplnit data, jak se ve stejném období měnil počet obyvatel správního obvodu Hořice. Ve světle údajů uvedených výše není překvapením, že počet obyvatel správního obvodu (včetně jeho centra Hořic) je v podstatně stabilní a nijak významně nekolísá. Rozdíl mezi nejvyšším a nejnižším počtem obyvatel je 425 a to představuje pohyb v rozmezí 2,2 %."},
            {id: 4, typ: 0, text: "Ale trvale klesající počet obyvatel města Hořic a současný nárůst počtu obyvatel obcí správního obvodu má jeden efekt – snižuje se podíl Hořic jako centra na celkovém počtu obyvatel správního obvodu. Na venkově, v obcích správního obvodu Hořice, už žije víc obyvatel, než ve vlastním centru Hořicích."},
        ],
        scroll: true
    },
    { 
        id: 5,
        hrefid: "problem/stavba-domu",
        nazev: "Jak se v Hořicích staví domy",
        nadpis: "Od začátku 90.tých let Hořice v počtu domů za Novou Pakou dále zaostávají. A zaostávají čím dál víc.",
        odstavce: [
            {id: 1, typ: 0, text: "V této části seriálu se budeme věnovat počtům domů v Hořicích, Nové Pace a okrajově i Jičíně. K tomuto tématu jsou k dispozici dlouhé časové řady dat ze sčítání lidu, domů a bytů. V tabulce jsou data o počtech domů za léta 1869 až 2021."},
            /*{id: 2, typ: 2, tr: [{id: 1, td: [{data: ""}, {data: 1869}, {data: 1880}, {data: 1890}, {data: 1900}, {data: 1910}, {data: 1921}, {data: 1930}, {data: 1950}, {data: 1961}, {data: 1970}, {data: 1980}, {data: 1991}, {data: 2001}, {data: 2011}, {data: 2021}]},
                                {id: 2, td: [{data: "Hořice", b:true}, {data: 759, b:true}, {data: 794, b:true}, {data: 791, b:true}, {data: 952, b:true}, {data: 1114, b:true}, {data: 1230, b:true}, {data: 1489, b:true}, {data: 1673, b:true}, {data: 1631, b:true}, {data: 1674, b:true}, {data: 1735, b:true}, {data: 2124, b:true}, {data: 2164, b:true}, {data: 2256, b:true}, {data: 2324, b:true}]},
                                {id: 3, td: [{data: "Nová Paka", b:true}, {data: 902, b:true}, {data: 944, b:true}, {data: 948, b:true}, {data: 1058, b:true}, {data: 1238, b:true}, {data: 1336, b:true}, {data: 1625, b:true}, {data: 1892, b:true}, {data: 1796, b:true}, {data: 1835, b:true}, {data: 1847, b:true}, {data: 2231, b:true}, {data: 2333, b:true}, {data: 2497, b:true}, {data: 2588, b:true}]},
                                {id: 4, td: [{data: "Jičín", b:true}, {data: 653, b:true}, {data: 755, b:true}, {data: 818, b:true}, {data: 908, b:true}, {data: 1057, b:true}, {data: 1146, b:true}, {data: 1470, b:true}, {data: 1811, b:true}, {data: 1745, b:true}, {data: 1826, b:true}, {data: 2064, b:true}, {data: 2408, b:true}, {data: 2441, b:true}, {data: 2623, b:true}, {data: 2759, b:true}]},
                                {id: 5, td: [{data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: -106, b:true}, {data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: "", b:true}, {data: -107, b:true}, {data: -169, b:true}, {data: -241, b:true}, {data: -264, b:true}]},
                            ]},*/
            {id: 2, typ: 3, src: "problemy/dil5-t.webp"},
            {id: 3, typ: 0, text: "Za povšimnutí stojí dvě skutečnosti."},
            {id: 4, typ: 0, text: "Na počátku časové řady v roce 1869 bylo v Jičíně, ve srovnání s Novou Pakou, významně méně domů. Více domů než v Jičíně bylo i v Hořicích. V počtu domů předstihl Jičín Hořice při sčítání lidu v roce 1950 a Novou Paku až krátce po roce 1970. K tomu došlo zhruba 10 let po zásadní územněsprávní reformě, kdy se Jičín stal sídlem okresu. Potom už Jičín jen rostl."},
            {id: 5, typ: 0, text: "Z pohledu Hořic je zajímavá ještě jiná věc. Po celou dobu, kdy se provádějí sčítání lidu, je v Nové Pace více domů, než v Hořicích. Dvakrát, v letech 1921 a 1991, se Hořice přiblížily Nové Pace „na dosah“ a rozdíl v počtu domů byl 106 resp. 107 domů. Od začátku 90.tých let ale Hořice v počtu domů za Novou Pakou dále zaostávají. Při sčítání lidu v roce 2021 to bylo o 264 domů."},
        ],
        scroll: true
    },
    { 
        id: 6,
        hrefid: "problem/vynosy-z-majetku",
        nazev: "Výnosy z městského majetku v mlžném oparu",
        nadpis: "Jak se říká v hantýrce účetních, 110 milionů korun z výnosu městského majetku bylo v rozpočtu města prostě rozpuštěno.",
        odstavce: [
            {id: 1, typ: 0, text: "Nezanedbatelnými příjmy rozpočtu města jsou výnosy z majetku města. Rozumíme jimi příjmy z pronájmu bytů a nebytových prostor, příjmy z prodeje pozemků a příjmy z prodeje bytů a nebytových prostor."},
            {id: 2, typ: 0, text: "Tyto výnosy z majetku města byly v posledních dvou volebních obdobích 2014 – 2021 následující."},
            /*{id: 3, typ: 2, tr: [{id: 1, td: [{data: ""}, {data: "příjem v letech 2014-21", b:true}, {data: "opravy a údržba v letech 2014-21", b:true}, {data: "zpětně investováno %", b:true}]},
                                {id: 2, td: [{data: "pronájmy bytů"}, {data: "25 548 799,- Kč"}, {data: "9 232 826,- Kč"}, {data: "36,1"}]},
                                {id: 3, td: [{data: "pronájmy nebyt. prostor"}, {data: "23 100 773,- Kč"}, {data: "7 989 553,- Kč"}, {data: "34,6"}]},
                                {id: 4, td: [{data: "mezisoučet", b:true}, {data: "48 649 572,- Kč"}, {data: "17 222 379,- Kč"}, {data: ""}]},
                                {id: 5, td: [{data: "prodeje pozemků"}, {data: "84 318 067,- Kč"}, {data: "---------"}, {data: ""}]},
                                {id: 6, td: [{data: "prodeje bytů"}, {data: "865 503,- Kč"}, {data: "---------"}, {data: ""}]},
                                {id: 7, td: [{data: ""}, {data: "133 833 142,- Kč", b:true}, {data: "17 222 379,- Kč", b:true}, {data: ""}]},
                            ]},*/
            {id: 3, typ: 3, src: "problemy/dil6-t.webp"},
            {id: 4, typ: 0, text: "Celkové výnosy z majetku města za poslední dvě volební období byly zhruba 130 milionů korun. To odpovídá přibližně jednomu ročnímu daňovému příjmu městského rozpočtu. Na místě je tedy otázka, za co byly utraceny tyto peníze, které do městského rozpočtu přinesl městský majetek."},
            {id: 5, typ: 0, text: "Pronájmy bytů a nebytových prostor během osmi let přispěly do městského rozpočtu celkem něco málo přes 48 milionů korun. Ze závěrečných účtů města vyplývá, že ve formě oprav a údržby bylo do tohoto majetku zpětně investováno 17 milionů. Za co město utratilo zbývajících 31 milionů se skrývá za mlžným oparem nesčetných rozpočtových opatření prováděných v každém rozpočtovém roce."},
            {id: 6, typ: 0, text: "O osudu více než 80 milionů korun z prodeje městských pozemků a bytů se nedá říct prakticky vůbec nic. Když k nim navíc přidáme dalších 31 milionů z pronájmů, jedná se o 110 milionů korun za dvě poslední volební období. Co se s nimi stalo? Jak se říká v hantýrce účetních, těchto 110 milionů korun z výnosu městského majetku bylo v rozpočtu města prostě rozpuštěno."},
        ],
        scroll: true
    },
    { 
        id: 7,
        hrefid: "problem/mene-obvatel-mene-penez",
        nazev: "Méně obyvatel – méně peněz",
        nadpis: "Za poslední čtyři roky přišel městský rozpočet, v důsledku úbytku obyvatel, celkem o 5 590 500,- Kč.",
        odstavce: [
            {id: 1, typ: 0, text: "Daňový výnos přidělovaný ze státního rozpočtu na jednoho obyvatele města je vázán na vývoj celé ekonomiky a výběr daní státem. V posledním desetiletí, až na výjimku v roce 2020, daňový výnos pro město rostl a v roce 2021 činil 15 700,- Kč. Daňové výnosy plynoucí ze státního rozpočtu do rozpočtu města jsou dány zákonem a jsou přidělovány podle počtu obyvatel. Pravidlo je proto jednoduché – méně obyvatel znamená méně peněz pro město."},
            {id: 2, typ: 0, text: "Co znamenalo pro městský rozpočet to, že v posledních čtyřech letech ubývali v Hořicích obyvatelé, ukazuje tabulka."},
            /*{id: 3, typ: 2, tr: [{id: 1, td: [{data: ""}, {data: "Hořice", b:true}, {data: "Nová Paka", b:true}, {data: "Nový Bydžov", b:true}, {data: "Sobotka", b:true}, {data: "Jičín", b:true}, {data: "Jaroměř", b:true}]},
                                {id: 2, td: [{data: "2017"}, {data: "150,3"}, {data: "150"}, {data: "140,8"}, {data: "133,1"}, {data: "129,8"}, {data: "119,1"}]},
                                {id: 3, td: [{data: "2018"}, {data: "152"}, {data: "149,9"}, {data: "145,2"}, {data: "133,4"}, {data: "130,9"}, {data: "123"}]},
                                {id: 4, td: [{data: "2019"}, {data: "160,6"}, {data: "149,4"}, {data: "153,7"}, {data: "133,4"}, {data: "135,2"}, {data: "128,6"}]},
                                {id: 5, td: [{data: "2020"}, {data: "167,6"}, {data: "153"}, {data: "151"}, {data: "138"}, {data: "137,7"}, {data: "129,5"}]},
                                {id: 6, td: [{data: "2021"}, {data: "167"}, {data: "153,7"}, {data: "147"}, {data: "143,7"}, {data: "140,4"}, {data: "131"}]},
                                {id: 7, td: [{data: "přírůstek %", b: true}, {data: "11,1"}, {data: "2,5"}, {data: "4,4"}, {data: "8"}, {data: "8,2"}, {data: "10"}]},
                                {id: 8, td: [{data: "prům. věk 2021", b: true}, {data: "45,1"}, {data: "44,6"}, {data: "43,7"}, {data: "43,7"}, {data: "43,7"}, {data: "42,6"}]},
                            ]},*/
            {id: 3, typ: 3, src: "problemy/dil7-t.webp"},
            {id: 4, typ: 0, text: "Proto když v roce 2021 ubylo v Hořicích 165 obyvatel, dostalo město do svého rozpočtu o 2,6 milionu korun méně. S tím, jak se zvyšuje daňový výnos, zvyšuje se, při stejném úbytku obyvatel, ztráta městského rozpočtu. Během posledních deseti let se daňový výnos zdvojnásobil, a proto ztráta městského rozpočtu, při stejném úbytku obyvatel je dvojnásobná."},
            {id: 5, typ: 0, text: "Z tabulky je patrné, že tímto způsobem přišel za poslední čtyři roky městský rozpočet celkem o 5 590 500,- Kč."},
        ],
        scroll: true
    },
    { 
        id: 8,
        hrefid: "problem/v-mladych-neni-budoucnost",
        nazev: "V mladých Hořice zatím budoucnost nemají",
        nadpis: "Pro budoucnost města není postačující, když děti a mládež mají komfortní školy a sportoviště. Protože oni dospějí, dostudují a chtějí založit rodinu. Když ale v Hořicích nenajdou bydlení, tak se do Hořic buď nevrátí anebo definitivně odejdou.",
        odstavce: [
            {id: 1, typ: 0, text: "Heslo o dětech jako budoucnosti je všeobecně oblíbené. Platí to i v Hořicích. Ale odpověď na otázku, jestli se Hořice starají, aby děti skutečně byly jejich budoucností, není jednoznačná."},
            {id: 2, typ: 0, text: "Pravdivým ukazatelem toho, jestli jakékoliv město může vidět svoji budoucnost v mladých lidech je, paradoxně, index stáří. Index stáří říká, kolik obyvatel města ze skupiny 65+ připadá na sto obyvatel ze skupiny 0 – 14 let. Tabulka ukazuje vývoj indexu stáří Hořic a dalších pěti měst v období let 2017 až 2021."},
            {id: 3, typ: 3, src: "problemy/dil8-t.webp"},
            {id: 4, typ: 0, text: "Ze všech šesti měst mají Hořice nejvyšší index stáří 167. Znamená to, že v Hořicích na každých 100 mladých ve věku 0-14 let připadá 167 seniorů ve věku 65+. Tuto nepříznivou skutečnost ještě umocňuje fakt, že Hořice vykazují nejrychlejší růst indexu stáří během posledních pěti let a Hořice také mají z těchto šesti měst nejvyšší průměrný věk obyvatel 45,1 roku. Mladí se do Hořic nehrnou."},
            {id: 5, typ: 0, text: "Vývoj indexu stáří svědčí o tom, že mladí v tuto chvíli nejsou budoucností Hořic. Pro budoucnost města není postačující, když děti a mládež mají komfortní školy a sportoviště. Oni totiž dospějí a dostudují a chtějí založit rodinu. Když však v Hořicích nenajdou bydlení, tak se do Hořic buď nevrátí anebo definitivně odejdou. Proto je nutné na první místo v prioritách postavit nové byty. Potom budou mít Hořice v mladých skutečně svoji budoucnost."},
        ],
        scroll: true
    },
    { 
        id: 9,
        hrefid: "problem/fond-rozvoje-bydleni",
        nazev: "Fond rozvoje bydlení jako zbytečná formalita",
        nadpis: "Město má zřízený Fond rozvoje bydlení, který slouží k poskytování zápůjček na opravy rekonstrukce a modernizace bytových a rodinných domů. Za posledních 5 let byla celková částka zápůjček poskytnutých z tohoto fondu 442 900,- Kč.",
        odstavce: [
            {id: 1, typ: 0, text: "Vnitřní směrnice, kterou byl Fond rozvoje bydlení ustanoven, byla zastupitelstvem města schválena v červnu roku 2014. Fond hospodaří především s prostředky rozpočtu města a je možné je používat výhradně k poskytování zápůjček úročených sazbou 2% p.a. při nejdelší lhůtě splatnosti 5 let. V tabulce je přehled hospodaření šesti fondů zřízených městem za posledních pět let."},
            {id: 2, typ: 3, src: "problemy/dil9-t.webp"},
            {id: 3, typ: 0, text: "Je vidět, že za posledních pět let bylo ze všech fondů vynaloženo do určených oblastí celkem 7,5 milionu korun. Z Fondu rozvoje bydlení byly poskytnuty zápůjčky za méně než půl milionu korun, přitom za poslední dva roky nebyly poskytnuty žádné zápůjčky. Z toho je zřejmé, že takto ustavený fond je naprosto zbytečnou formalitou, která pro rozvoj bydlení ve městě vůbec nic neznamená."},
            {id: 4, typ: 0, text: "Za pozornost stojí Fond rezerv a rozvoje. Tento fond je celá léta jen mrtvou schránkou a byl využit jen jednou, aby tam byla odložena dotace Královéhradeckého kraje na dostavbu Masarykovy věže samostatnosti – která se neuskutečnila a peníze byly vráceny."},
            {id: 5, typ: 0, text: "O tom, že rozvoj města byl v posledních letech zcela opomíjen, svědčí zdánlivá maličkost. Dva ze šesti fondů, které mají v názvu slovo rozvoj, nebyly buď vůbec využívány anebo zcela minimálně. Hojně je naopak využíván Fond podpory sportu. Z celkové částky vyplacené ze všech fondů, bylo plných 44% použito na podporu sportu a jen necelých 6% na rozvoj bydlení. To vypovídá vše o prioritách města v posledních letech."},
        ],
        scroll: true
    },
    { 
        id: 10,
        hrefid: "problem/za-rakouska-48-domu-nyni-4",
        nazev: "Za Rakouska za rok 48 nových domů v Hořicích – v současnosti 4",
        nadpis: "I když se to možná bude zdát zvláštní, k tomuto problému se poměrně obtížně hledají spolehlivá a úplná data. Jedním zdrojem je tištěný, vcelku spolehlivý a veřejně dostupný Seznam hořických domů, který zachycuje stav k září 1927 a končí číslem popisným 1223 v Tylově ulici. Tento seznam sestavil K. Novotný a doplnil J. Řehák. Další zdroje jsou údajně úřední, ale neautorizované, data nejsou vždy úplná a netvoří nepřetržitou řadu. Přes tyto problémy je možné si udělat obrázek, jakým tempem se v Hořicích stavěly domy dříve a dnes.",
        odstavce: [
            {id: 1, typ: 0, text: "Pro porovnání intenzity výstavby domů v Hořicích za posledních 150 let jsme vybrali tři třicetiletá období. Pokrývají období rakouského mocnářství, reálného socialismu a období po sametové revoluci. Výsledky jsou následující."},
            {id: 2, typ: 3, src: "problemy/dil10-t.webp"},
            {id: 3, typ: 0, text: "V období rakouského mocnářství a reálného socialismu je intenzita výstavby domů v Hořicích srovnatelná. V obou obdobích také počet obyvatel Hořic rostl. Třicetileté období na přelomu 19. a 20. století se bez nadsázky dá nazvat zlatým věkem Hořic a to nejen co do intenzity výstavby domů. Během dvou let byly postaveny dvě školy (Daliborka, Habr), vznikly smysluplné a prosperující střední školy aj."},
            {id: 4, typ: 0, text: "Ve srovnání s oběma předchozími obdobími potom nelze poslední třicetiletí pojmenovat jinak než úpadek města. Výstavba domů i bytů se prakticky zastavila a to je hlavní příčinou nejen nebývalého úbytku obyvatel, ale na to navazujících dalších nepříznivých okolností: stárnutí obyvatel, úpadek podnikání (zejména živnostníků), snižování kvality života a další."},
            {id: 5, typ: 0, text: "Pro konkrétní srovnání historie a současnosti jeden příklad. Za jeden jediný rok 1911, kdy byl starostou Hořic MVDr. Josef Fejfar, bylo v Hořicích v 17 ulicích postaveno celkem 48 domů. Za šest let 2014-2020 bylo v Hořicích vydáno k dokončeným rodinným domům zhruba 30 čísel popisných. Jinak řečeno, za starosty MVDr. Josefa Fejfara bylo za jeden rok postaveno v Hořicích více domů, než za šest let, kdy byl starostou města Aleš Svoboda. Souvislost mezi intenzitou výstavby domů pro bydlení a změnami počtu obyvatel města jsou zcela jasné."},
        ],
        scroll: true
    },
    { 
        id: 11,
        hrefid: "problem/bytove-domy",
        nazev: "Bytové domy – Šíma, Vrba a potom už skoro nic",
        nadpis: "Je třeba říct, že bytové domy se v Hořicích stavěly vlastně jen za reálného socialismu. Proto má smysl porovnávat výstavbu bytových domů ve druhé polovině minulého století a v období po sametové revoluci. Výsledkem je zjištění, že za posledních deset let byly v Hořicích postaveny dva bytové domy s 18 byty.",
        odstavce: [
            {id: 1, typ: 0, text: "Při prvním sčítání lidu po druhé světové válce klesl počet obyvatel Hořic na 7 700. Toto číslo zůstalo prakticky stejné až do sčítání v roce 1961, kdy se změnilo územní uspořádání a Hořice byly začleněny do okresu Jičín. Přehled o vývoji výstavby bytových domů v Hořicích je v následující tabulce."},
            {id: 2, typ: 3, src: "problemy/dil11-t.webp"},
            {id: 3, typ: 0, text: "Výstavba jak domů, tak i bytových domů v období reálného socialismu v Hořicích z větší části zacelila rány způsobené především dvěma světovými válkami. Výstavba rodinných domů byla významná, ale výstavba bytových domů přinesla téměř třikrát víc nových bytů, než rodinné domy."},
            {id: 4, typ: 0, text: "O výstavbě bytových domů, v období po sametové revoluci, se dá hovořit nanejvýš ve druhé polovině devadesátých let a krátce po roce 2000. V bytových domech bylo v té době za devět let postaveno 103 bytů za starosty Šímy a 67 bytů za starosty Vrby. Za posledních deset let (do 9/2020) vzniklo v Hořicích ve 2 nových bytových domech celkem 18 (osmnáct) bytů."},
            {id: 5, typ: 0, text: "Sečteno, podtrženo: Po sametové revoluci bylo v Hořicích za třicet let postaveno 125 rodinných domů a 211 bytů v bytových domech."},
            {id: 6, typ: 0, text: "**Výsledek:** Historicky největší úpadek počtu obyvatel města Hořic v mírových dobách."}
        ],
        scroll: true
    },
    { 
        id: 12,
        hrefid: "problem/zivnostnik-vymirajici-druh-podnikatele",
        nazev: "Živnostník – v Hořicích vymírající druh podnikatele",
        nadpis: "Živnostník druh podnikatele, který nepotřebuje ke svému živobytí žádné rozsáhlé haly a robotická pracoviště. Potřebuje hlavně lidi, kteří potřebují jeho služby. Platí jednoduché pravidlo – kde nejsou lidi, tam nejsou živnostníci.",
        odstavce: [
            {id: 1, typ: 0, text: "Významným kritériem pro posouzení stavu podnikatelského prostředí ve městě je počet podnikatelských subjektů. Ovšem ne těch jen registrovaných, ale těch, kteří jsou skutečně aktivní, provozují svoji živnost. Pro srovnání jsou v následující tabulce uvedeny údaje nejen za Hořice, ale i údaje za Novou Paku a Nový Bydžov."},
            {id: 2, typ: 3, src: "problemy/dil12-t.webp"},
            {id: 3, typ: 0, text: "Podíl aktivních subjektů na celkovém počtu registrovaných subjektů mají Hořice nejnižší ze všech tří měst. Nejvýraznější je tento rozdíl u živnostníků. Ve srovnání s Novým Bydžovem mají Hořice podíl aktivních živnostníků nižší o 5 %."},
            {id: 4, typ: 0, text: "Pro představu, to znamená, že v Hořicích by muselo být přibližně o 100 aktivních živnostníků víc, než je nyní, aby jejich podíl na počtu registrovaných živnostníků byl v Hořicích stejný, jako je v Novém Bydžově."},
            {id: 5, typ: 0, text: "Odpověď na otázku, proč to je tak, je třeba hledat ve velice rozdílném vývoji počtu obyvatel Nového Bydžova a Hořic. V Novém Bydžově ubylo za posledních osm let 70 obyvatel, ale v Hořicích se za stejnou dobu snížil počet obyvatel o 351. Jestliže v Hořicích ubývají obyvatelé pětkrát rychleji než v Novém Bydžově, ubývají pětkrát rychleji potenciální zákazníci živnostníků a prosazuje se jednoduché pravidlo – kde nejsou lidi, tam nejsou živnostníci."}
        ],
        scroll: true
    }
]

export default problemy