const kandidati = [
    {
      id: 1,
      titul: "Ing. ",
      jmeno: 'Ivan Stuchlík',
      vek: 60,
      povolani: 'Obchodní manager v IT, organizátor Retro diskoplesu',
      imageSrc: 'kandidati/IvanStuchlik.webp',
      predstaveni: "Jako prvního vám představíme rodilého Hořičáka, který má celý život velmi blízko ke kultuře a nyní se rozhodl aktivně vstoupit do veřejného života. Dost možná jste ho někdy poznali jako toho hudebního nadšence, co na oslavách, svatbách nebo oblíbených Retro Discoplesech až do brzkých ranních hodin pouští jeden hit za druhým. Jeho jméno je Ivan Stuchlík a jako prvního ho představujeme proto, že stojí v našem čele. Nesmírně nás těší, že coby kandidáta na starostu můžeme Hořičákům nabídnout slušného, usměvavého sympaťáka diplomatické povahy, který má díky celoživotní zkušenosti z byznysu správný tah na branku, čistý štít a chuť do práce. Tedy přesně takového kandidáta, jakého jsme tam od začátku chtěli mít. Více o sobě prozradí sám. Odpusťte prosím poněkud delší stopáž. Kandidáta na starostu představujeme jen jednou. Navazující medailonky budou úspornější 😀",
      kdoJsem: "Od narození žiji v Hořicích a nemám v plánu to měnit. Jsem zkušený senior manažer s dlouholetými zkušenostmi z řízení obchodu a realizace velkých IT projektů u významných zákazníků především v oblasti utilit, financí, výrobních podniků a státní správy. V oblasti informačních technologií pracuji více než 30 let a prošel jsem si řadu především obchodních a projektových pozic. V současnosti pracuji jako obchodní manažer ve společnosti VISITECH a zajišťuji obchodní i projektovou činnost u našich zákazníků v rámci České a Slovenské republiky. Ve volném čase jsem už od 80. let DJ. Tenkrát se tomu ještě říkalo diskžokej. Pořád mě baví vracet se domů ve čtyři ráno nabitý tím, jak hudba spojuje lidi. Jsem ženatý, hrdý otec našich dvojčat a čerstvě dvojnásobný děda. ",
      procKandiduji: "V oblasti politiky jsem se nikdy nepohyboval a ani jsem to neměl v úmyslu. Ale nemůžu už dál pasivně přihlížet tomu, jak se naše krásné město vylidňuje, jak se tu věčně řeší, jak správně konzervovat minulost, místo abychom koukali dopředu a správně chytili příležitosti, které nám padají do klína. Bydlení, pracovní příležitosti, infrastruktura – mám spoustu otázek, spoustu výtek. Mám dvě dospělé děti, které bohužel v Hořicích nezůstaly a odešly do Prahy a do Brna. Je mi to líto, ale naprosto je chápu, protože v Hořicích by ve svých oborech (IT a marketing) opravdu nenašly odpovídající uplatnění. Tento vlak ujel, ujela jich už spousta. Co se dá dělat? Cítím se poučen a nabízím, že vyhrnu rukávy a všemi silami se zasadím o to, abych tento trend obrátil.",
      coNabizim: "Ze své praxe mám bohaté zkušenosti s řízením projektů a s komunikací s významnými klienty. Jinými slovy – vím, co se musí stát, aby se něco stalo a umím se s lidmi domluvit. Celý svůj aktivní život musím správně prioritizovat, investovat energii a prostředky do projektů, které se skutečně dotáhnou a nezapadnou pod stůl. Správná komunikace je základem každého úspěšného vztahu, i radnice a občanů města. Vím, že vám neříkám nic nového. Přesto jsem přesvědčený, že ve vztahu mezi radnicí, občany a podnikatelskými i ostatními subjekty ve městě je sakra co zlepšovat. Nejen že mám chuť to zlepšit. Dokonce si myslím, že vím, kudy do toho.",
      coZmenit: "Přál bych si, aby Hořice byly dobrou adresou. Aby přibývalo lidí, jako jsem já, kdo se sem narodí a nemají důvod odsud mizet. Aby po studiích zůstávali v Hořicích a měli možnost najít tady slušné bydlení a zajímavou práci. Přál bych si, aby Hořice byly atraktivnější i pro lidi odjinud. K tomu je mimo jiné vytěžit maximum z budoucího napojení Hořic na dálnici. Těch témat je pochopitelně více. Zájemce rád nasměruji na náš volební program, který v těchto dnech finalizujeme. Tohle je pro mě téma číslo jedna. Hořice zaznamenaly v minulosti významný růst několikrát: napojení na železnici, napojení na silnici, kterou dnes známe jako pětatřicítku. Dálnice by mohla znamenat podobný rozkvět. Ale někdo na tom musí pracovat!",
      motto: "Nikdo nesliboval, že život bude jednoduchý."
    },
    {
      id: 2,
      titul: "",
      jmeno: 'Soňa Šubrtová',
      vek: 44,
      povolani: 'Technolog výroby',
      imageSrc: 'kandidati/SonaSubrtova.webp',
      predstaveni: "Toto je Soňa Šubrtová. Ze základky si ji nejspíš nepamatujete, protože pochází z Mlázovic. V Hořicích však žije dost dlouho na to, aby ji to táhlo zapojit se zde do veřejného života. Hořičákům doporučujeme její volbu pro její manažerské zkušenosti a povahu, ve které se v optimálním poměru snoubí laskavost s asertivitou. Soňa umí vzít za práci, je zvyklá odolávat tlaku a řešit krizové situace a především má ráda nové výzvy. Ten typ ženské, které nabídnete kandidaturu, ona se zamyslí a když řekne ano, jde do toho „all-in“.",
      kdoJsem: "Pocházím z nedalekých Mlázovic. Hned po střední škole jsem po hlavě skočila do gastronomie. Vybudovali nebo přestavěli a zároveň jsme provozovali hostinec ve Vojicích a restauraci U Kameníka v Ostroměři. To trvalo asi 8 let. Další roky jsem se živila jako obchodní zástupce v oblasti potravinářství. Na severu a východě Čech jsem se starala snad o všechny nemocnice, domovy důchodců, školy, školky a dětské domovy. Zkušenost k nezaplacení. Posledních 11 let pracuji jako technolog a vedoucí výroby ve firmě Pravé hořické trubičky s.r.o. Je to rozmanitá práce, kde se potravinářství potkává s ekonomikou provozu a všemožným papírováním, ale především pracuji s lidmi.\nV Hořicích žiji necelých 6 let a ačkoli to není dlouho, můžu s jistotou říct, že jsem si je zamilovala jako krásné město k žití. Jako holka z nedaleké vesnice jsem Hořice samozřejmě odmala znala a o životě zde jsem snila. Tento sen jsem si, jak je asi evidentní, splnila. Teď bych chtěla, aby byly Hořice podobně atraktivní pro nastupující generace, jako byly kdysi pro mě.\nJsem hrdou matkou dvou vysokoškoláků, nejmladší dceru mám na ZŠ. Ve volných chvílích ráda sportuji, jezdím na výlety po okolí s mými dětmi. Sama ráda chodím po Hořicích, kde už znám každou uličku, anebo si jen tak sednu s pěknou knihou a dobrým vínem.",
      procKandiduji: "To se ptám i sama sebe. Do politiky mě to dříve netáhlo. Ano, mohla bych se věnovat rodině, přátelům, zahrádce nebo cestování tak, jako jsem to dělal doposud. Došlo mi však, že pokud ráda trávím čas s rodinou, měla bych se postarat o to, aby mi v Hořicích všechny tři moje děti rády zůstaly. A spolu s nimi také jejich vrstevníci. Hořice jsou krásné město, přesto zde dochází rapidnímu úbytku obyvatel. Do budoucna to představuje obrovský problém nejen pro zdejší školy, cestovní ruch nebo podnikání, ale dotýká se to snad všech myslitelných oblastí. Cítím potřebu nestrkat před tím hlavu do písku a zapojit se do řešení. Ostatně, v tomto se na naší kandidátce shodneme všichni.",
      coNabizim: "Více než 20 let zkušeností s prací v soukromém sektoru jako podnikatel, obchodník nebo vedoucí pracovník. Jak se říká, vím, kolik stojí chleba. Vím, jaké to je, když musí žena děti nejen vychovat, ale taky uživit. Myslím, že mě tato „škola života“ naučila dobře prioritizovat a bavit se prací.",
      coZmenit: "Velkých slibů a megalomanských plánů slýcháme od politiků všude až až, a já to nikdy neměla ráda. Vize ať mají vizionáři. Já se Hořičákům nabízím jako zkušený praktik, který ty vize umí krůček po krůčku měnit ve skutečnost. V Hořicích je hezky. Chci, aby v nich bylo ještě lépe.",
      motto: ""
    },
    {
      id: 3,
      titul: "Ing. Mgr. ",
      jmeno: 'Helena Hyšková',
      vek: 51,
      povolani: 'Zástupce vedoucího oddělení Krajského úřadu KHK',
      imageSrc: 'kandidati/HelenaHyskova.webp',
      predstaveni: "Naší trojkou je celoživotní Hořičačka, paní Helena Hyšková. Jednapadesátiletá úřednice z Krajského úřadu Královéhradeckého kraje, to možná na první pohled působí jako kariérní profil, který člověka možná spíš vyděsí, než nadchne. Ovšem pouze pokud Helenu neznáte. Vybrali jsme ji na jednu z předních příček a dobře víme proč. Je pro nás zosobněním toho, jaké lidi bychom si ve veřejné správě přáli. Zapálené, nadšené do zlepšování věcí, s jiskrou v oku, milé a s upřímnou snahou pomoci. Letos na jaře, na jedné z našich prvních společných schůzek, nám třeba jen tak mimochodem svěřila informaci, že právě obhájila diplomovou práci v oboru Prostorové plánování, jejímž předmětem byla nová varianta obchvatu Hořic. Proto ty dva tituly. Nyní pojďme k našemu standardizovanému dotazníku:",
      kdoJsem: "Po vystudování hořické Střední průmyslové školy kamenické jsem necelou dekádu pracovala jako restaurátorka na obnově kamenických a sochařských děl po celé republice. Po první rodičovské dovolené jsem začala novou etapu svého pracovního života na odboru životního prostředí Krajského úřadu v Hradci Králové, kde pracuji dodnes a kde nyní zastupuji vedoucího oddělení technické ochrany. Tato práce obnáší především komunikaci s mnoha odborníky na úseku územního plánování, s architekty, projektanty, pořizovateli územních plánů, ale také s veřejností. \nVystudovala jsem historii na FF UHK a v nedávné minulosti také prostorové plánování na Fakultě životního prostředí ČZU. Jsem svobodná, žiju s otcem mladšího z mých dvou dětí.",
      procKandiduji: "Dosud jsem nikdy nikam nekandidovala. Došlo mi však, že zásadní úkoly, před kterými město stojí, musíme řešit bezodkladně. Známé heslo říká, že: kdo, když ne my, kdy, když ne teď. A tak kandiduji.\nMojí silnou stránkou je, že dokonale znám chod úřadu. Právě proto vím, že i za úřadováním lze nalézt zase jen člověka, který buďto řešení nalézt chce, anebo hledá důvody řešení vůbec nehledat. S trochou nadsázky říkám, že slovo „nejde“ neexistuje. Ve své práci se vždy snažím hledat cesty pro řešení problémů, které se týkají i Hořic. Hořický úřad, dle mých zkušeností, toto nastavení ne vždy sdílí. Chci to změnit. ",
      coNabizim: "Jako vystudovaný historik a původní profesí restaurátorka si vážím úsilí, které město Hořice vynaložilo na opravu svých památek. Přesto si myslím, že je nejvyšší čas začít se věnovat rozvoji města a přidat do něj nějakou hodnotu také za naši generaci. Nabízím své bohaté zkušenosti z oblasti územního plánování, urbanistiky, prostorového plánování a životního prostředí. Nabízím drajv člověka, který se dvě dekády koukal z Hradce na to, jak se daří obcím a městům v celém kraji, a nemohl si nevšimnout, že v Hořicích to stále nějak drhne. ",
      coZmenit: "Je nutné udělat konečně z Hořic místo dobré pro život se vším, co k tomu patří. Bydlení, ale zároveň kvalitní veřejné prostory, což znamená naplno využít daností, kterými město disponuje již nyní. Nová výstavba by měla logicky doplňovat organicky rostlé město. Potřebujeme nejen developerské subjekty, ale také podnikatelské aktivity, které doplní potřebnou vybavenost v právě vznikajících čtvrtích města. Město má nový územní plán, nejdůležitější rozvojový dokument, který ale ani v nejmenším nevytěžuje. Máme plochy pro výstavbu, kde se nestaví. Proto si kladu otázku proč. Město Hořice se prohlašuje za město tradic, ale umíme přece také něco navíc, nového, máme zkrátka co nabídnout, jen se nebát. Ve všech zmíněných oblastech se cítím být platným partnerem do diskuze. A vás bych tímto chtěla požádat o podporu. Děkuji",
      motto: ""
    },
    {
      id: 4,
      titul: "Ing. ",
      jmeno: 'Hugo Trkal',
      vek: 33,
      povolani: 'OSVČ v oblasti marketingové komunikace a audiovizuální tvorby',
      imageSrc: 'kandidati/HugoTrkal.webp',
      predstaveni: "Náš kandidát s číslem čtyři je Ing. Hugo Trkal, kterého jste do městského zastupitelstva zvolili už v minulých volbách. Rocker, tak trochu bohém a zároveň svědomitý táta od rodiny, se kterým lze diskutovat téměř o čemkoliv. Kreativní hlava plná nápadů a vysmátý šprýmař, který ale umí být neústupný paličák. Bude si stát za tím, co považuje za správné, ale jeho zbraní bude vždycky logika, nikdy síla nebo pletichy.",
      kdoJsem: "Celý život žiji v Hořicích. S jednou malou přestávkou, kdy jsem si odskočil do Prahy vystudovat obor Marketingová komunikace na VŠFS v Praze. Pak jsem se do Hořic zase vrátil, protože doma je doma. Čtyři roky praxe v malé regionální PR agentuře mě naučily, že kreativita a chuť učit se nové věci jsou důležitější než obří rozpočty. Poslední dva roky pracuji na volné noze. Pro své klienty píšu, fotím nebo natáčím. Umím převést do slov a obrazu to, co by rádi poslali do světa. A protože mi záleží i na tom, co do světa můžu poslat svojí aktivitou já sám, jsem od roku 2018 zvoleným zastupitelem v Hořicích.\nŽiju hudbou. Dvě kapely, doma sbírka hudebních nástrojů, nahrávání, koncerty. Klidně řeknu, že jsem srdcem i duší muzikant. Tohle trochu neklidné hobby ale vždycky rád doplním výletem do přírody, ideálně zakončeným přátelským posezením u dobrého piva.\nJsem šťastně ženatý, mám dvě docela malé děti a jednoho docela velkého psa. Nejsem členem žádné politické strany.",
      procKandiduji: "Protože nevyklidím pole. Předchozí volby dopadly tak, že tři nejsilnější subjekty, které společně vládly, posílily, zatímco zbytek stran oslabil. Najednou stačily na sestavení většiny subjekty dva, třetí zamířil do opozice. To je v mnoha ohledech dobře, protože Hořice opozici postrádaly. Ale je pravděpodobné, že pokud na hřišti zůstanou stejní hráči, dopadne to letos velmi podobně.\nS tímto výhledem jsem nebyl moc spokojený. Nedělá dobrotu, když je u kormidla dlouho jedna parta. Chceme-li dosáhnout nějaké změny, je potřeba přidat hráče.\nTak jsme se společně s kolegou Zdeňkem Lhotou v přátelském duchu rozešli s kolegy z hořické ODS, za kterou jsme seděli v zastupitelstvu předchozí čtyři roky, a rozhodli se sestavit nový subjekt. Teď už jsou to dva proti dvěma a volby mohou dopadnout jinak. Měli jsme štěstí a našli k sobě partu sympatických, činorodých a pracovitých lidí. Ale zpátky k otázce proč kandiduji já. Protože bych si přál lepší Hořice.  ",
      coNabizim: "Nabízím pohled táty od mladé hořické rodiny, který řeší školky, školy, parky, hřiště, procházky a taky výhled do delší budoucnosti. Zároveň je to pohled liberálně založeného idealisty, který si myslí, že lidem se lépe daří, když je stát nechá svobodně konat. Nabízím své zkušenosti z oblasti propagace, médií, komunikace, kultury. Mám rád přímé jednání bez vytáček a pravdivou komunikaci, ať už jde o dobré nebo špatné zprávy.",
      coZmenit: "Možná je to jen můj dojem, ale nemůžu se ho zbavit. Dojem, že naše město tak trochu spí. Jako by nám mělo stačit, že jsou Hořice zasazené do krásné krajiny, mají dobrou polohu a zdědili jsme město plné památek a umění. Obávám se, že to nestačí. Zatímco leštíme rodinné stříbro, utíkají nám příležitosti a výzvy blízké budoucnosti. Není jen můj dojem, že v lecčems oproti jiným městům v okolí ztrácíme. Chtěl bych, aby Hořice chytly nový vítr do plachet a vyrazily na cestu vpřed. Potenciál k tomu máme.",
      motto: "Žít a nechat žít."
    },
    {
      id: 5,
      titul: "Bc. ",
      jmeno: 'Zdeněk Lhota',
      vek: 54,
      povolani: 'Jednatel obchodní společnosti, pořadatel kulturních akcí',
      imageSrc: 'kandidati/ZdenekLhota.webp',
      predstaveni: "Představení páté pozice naší kandidátky příhodně připadá na den, kdy si kandidát může s klidným svědomím odškrtnout, že po dobu deseti let udržel v Hořicích jeden z nejpopulárnějších hudebních festivalů svého druhu v zemi. Řeč je pochopitelně o Zdeňku Lhotovi! Kdysi popularizátor fitness a zdravého životního stylu, poté poslanec a v posledních letech především pořadatel kulturních akcí v areálu Zahrada (Areál Zahrada, The Legends Rock Fest)\nZdeňka známe jako neúnavného dříče, který jde za svým cílem tak dlouho, dokud není vše hotovo. Jako člověka vždy otevřeného věcné diskuzi, který nikdy nejde daleko ani pro pochvalu ani pro jasně formulovaný nesouhlas. Přestože svět politiky a veřejných funkcí opustil před více než deseti lety, v předchozích komunálních volbách díky preferenčním hlasům výrazně „poskočil“ a dnes tak obhajuje funkci řadového zastupitele.",
      kdoJsem: "„Řekni mi, co děláš a já ti povím, kdo jsi.“ Toto mírně upravené masarykovské heslo mám jako kompas v hlavě snad odjakživa. Mým povoláním jsou LED osvětlovací soustavy. Od projektové práce, přes výrobu, až po finální realizaci. Nejvíce úspěšných realizací máme na Letišti Václava Havla. Další mojí aktivitou, která je zřejmě více vidět, jsou kulturní akce, které s manželkou pořádáme. Festival The Legends Rockfest, na který každoročně zavítají tisíce nadšených fanoušků z celé republiky, právě oslavil deset let existence. Protože jsme poslední 3 roky konečně ve vlastním areálu, akcí přibývá. Zkoušíme vybudovat nové stálice kalendáře hořických akcí – ať už jde o Loučení s létem, Čarodějáles nebo Dřevosochání. Z uvedeného výčtu aktivit je asi zřejmé, že v životě potřebuji neustále něco tvořit nebo inovovat. Ve volném čase jsem dřív mnohem více sportoval, četl a cestoval. V posledních pár letech většinu volného času spolkne další zvelebování a údržba areálu Zahrada. Jsem ženatý, mám dvě dcery a jednu vnučku. ",
      procKandiduji: "Politické zkušenosti ze zastupitelstva, městské rady a později Poslanecké sněmovny jsem sice v roce 2011 pověsil na hřebík a vystoupil ze strany, ale životní ideály mi zůstaly. Když mi v roce 2018 nabídli, abych jako nestraník kandidoval, tak trochu do počtu, řekl jsem si proč ne. K mému překvapení jsem tenkrát byl zvolen a už v tom zase lítám. I když jsem ze světa viděl docela dost, musím říct, že Hořice miluji. Co se posledních osmi let týká, jsem nesmírně rád za každý metr opravené ulice, za každou obnovenou fasádu či budovu, za každou vysazenou kytičku. Rozvoj města však považuji za podvázaný, leckteré kroky vedení města považuji za nehospodárné nebo neefektivní. Člověk má na výběr dvě možnosti: na všechno se vykašlat, zabrblat si, ale jinak si dělat jen to své. Nebo se pokusit to zlepšit. U mě holt zvítězilo to druhé.",
      coNabizim: "Ve svém životě jsem měl štěstí, že jsem mohl poznat řadu významných osobností. Mezi jinými prvního porevolučního tajemníka MěÚ, pana Jaroslava Cejnara. Byla to doba, kdy na MěÚ Hořice vládla noblesa. Pan Cejnar mi tehdy řekl, že prý mám dar organizačních schopností. Že prý je to něco, s čím se lidé rodí, co se nedá naučit. Doporučil mi, ať s tím pracuji. Zásadním způsobem ovlivnil mé životní směřování. Proč to zmiňuji? Svým angažmá v tomto projektu chci nabídnout právě své organizační schopnosti a letité zkušenosti. Rád budu užitečný a prospěšný.",
      coZmenit: "Domnívám se, že život každého města stojí především na třech pilířích: na podmínkách pro trvalé bydlení, pracovních příležitostech a cestovním ruchu. Nejsme oázou uprostřed pustiny. I mezi městy vládne konkurence. Když se tyto základní pilíře začnou opomíjet, dříve nebo později se začne město nenápadně vylidňovat, negativní trendy se pak dotknout také všech ostatních oblastí. Hořice mají díky svým předkům úžasnou historii, skvělé lidi, mimořádný potenciál. Ale ty tři zmíněné pilíře považuji za dlouhodobě zanedbané, nelíbí se mi, co to působí dnes, a bojím se, co to způsobí do budoucna, když se s tím nezačne něco dělat. Proto za svůj prvořadý úkol považuji být nápomocen vstupu nových kvalitních lidí do vedení města. A na kandidátce, které říkáme Hořický STAN, takoví lidé jsou. Z mého pohledu je už jen vstup nových osobností do komunální politiky hodnotou sám o sobě. Byl bych rád, kdyby jim voliči města Hořic jako celému týmu dali šanci.",
      motto: "Bez bolesti není růstu. Kde je vůle, tam je cesta."
    },
    {
      id: 6,
      titul: "",
      jmeno: 'Václav Schejbal',
      vek: 57,
      povolani: 'OSVČ, revizní technik PO, organizátor Retro diskoplesu',
      imageSrc: 'kandidati/VaclavSchejbal.webp',
      predstaveni: "Naším dalším kandidátem je Vašek Schejbal a kdo ho zná, tak potvrdí, že je to originál každým coulem. Dost možná jste ho poznali buď jako revizního technika hasicích přístrojů, kterým je přes 30 let, anebo jako diskžokeje se čtyřicetiletou praxí, který v posledních letech bývá k vidění a slyšení zejména na Retro Discoplesech.",
      kdoJsem: "V Hořicích žiju od roku 1988, od té doby jsem také ženatý. Jsem otec dvou dcer a dědeček jednoho vnuka. Moje záliby jsou vinylové desky, tenis a volejbal. Dvě věci, které jsou pro mě osudové – diskotéky a hasicí přístroje.",
      procKandiduji: "V dnešní době postrádám více porozumění, lehkosti a radosti ze života a to jsou mimo jiné moje důvody, které mě přivádějí na kandidátku Hořického STANu. Kandidaturu mi nabídl můj dlouholetý DJský kolega Ivan Stuchlík a já si řekl, že rád pomůžu dobré věci.",
      coNabizim: "Nabízím svoje životní zkušenosti a svérázný způsob jednání založený na lehkosti a humoru.",
      coZmenit: "Hlavní potřebu změny v současné době vidím na úrovni mezilidských vztahů. Soupeření, závist a lidská hloupost zahnaly někam do kouta zdravý lidský rozum.",
      motto: "Bez radosti je život jenom čekání na konečnou stanici."
    },
    {
      id: 7,
      titul: "",
      jmeno: 'Monika Šanderová',
      vek: 49,
      povolani: 'Podnikatelka',
      imageSrc: 'kandidati/MonikaSanderova.webp',
      predstaveni: "Představujeme další ženu na naší kandidátce. Je jí Monika Šanderová, rodačka z Hořic, matka dvou dětí a také úspěšná podnikatelka, jejíž velkoobchod se stavební chemií je na trhu více než 20 let a je významným konkurentem velkých hráčů v oboru. Rozhodná žena se zdravým sebevědomím, která se nebojí mluvit úplně na rovinu.",
      kdoJsem: "V Hořicích jsem chodila na základní i střední školu, založila zde rodinu a vychovala zde své dvě děti. Za prací jsem nejprve dojížděla do Hradce Králové, kde jsem byla ředitelkou pobočky jedné mezinárodní firmy. Později mi začalo dávat větší smysl žít a pracovat na jednom místě, tak jsem založila vlastní firmu se sídlem v Hořicích. Můj velkoobchod zásobuje stavebniny a jiné maloobchody speciálními stavebními materiály. Život ale není jen práce, svoje zkušenosti jsem využila také při dobrovolné podpoře studentských aktivit ve Spolku přátel gymnázia, jehož jsem již několik let předsedkyně. Ve zbývajícím volném čase se věnuji hlavně rodině, ale také proháním svoje koloběžky, plavu, chodím pěšky, relaxuji v sauně, starám se o zahrádku, vyjdu na pěknou kulturní akci nebo navštívím kamarádku.",
      procKandiduji: "Komunální politiku beru jako službu lidem a městu, které mi dalo opravdu mnoho. Městu, ve kterém chci ještě nějaký čas žít, bydlet, podnikat a nechci tu jen přežívat a nečinně přihlížet.",
      coNabizim: "Především své letité zkušenosti z oblasti řízení firmy a obchodu. Má firma je poměrně malá, což mě nutí mít široký záběr od marketingu až po finanční řízení. A vždy si musím umět poradit.",
      coZmenit: "Kdyby volby mohly něco změnit, dávno by je zakázali. Nicméně určitě jde spoustu věcí v budoucnosti dělat lépe. Vidím určitý potenciál ve zlepšení komunikace města s občany, jejich větší zapojení do rozhodování o naší společné budoucnosti. Také bych se zasadila o větší podporu místních organizací i podnikatelů a jejich vzájemnou spolupráci.",
      motto: "Jsem původem zemědělec a vím, že když dnes zasadím kvalitní semínko, bude v budoucnu dobrá úroda nejen pro mě, ale i pro moje potomky."
    },
    {
      id: 8,
      titul: "",
      jmeno: 'Martin Sodomek',
      vek: 37,
      povolani: 'Strojník vodního hospodářství, organizátor Cultural reggae vibez',
      imageSrc: 'kandidati/MartinSodomek.webp',
      predstaveni: "Naším dalším kandidátem je Martin Sodomek, kterému málokdo řekne jinak než Soda. Usměvavý sympaťák, který přes festival Cultural Reggae Vibez přilákal do Hořic za uplynulé dvě dekády tisíce návštěvníků z tuzemska i ze zahraničí. Činorodý mladý muž s pestrou skladbou zájmů a dovedností. Více informací v jeho medailonku.",
      kdoJsem: "Jsem hrdý otec a šťastný manžel, pocházím z Hořic. Znát mě můžete jako jednoho z pořadatelů největšího reggae festivalu v České republice, kde jsme za 19 let existence hostili umělce z více než 40 zemí světa. Píšu hudební recenze a reportáže z koncertů a festivalů. Jsem pokračovatelem lakýrnického řemesla a zaměstnancem Sportovního zařízení Hořice. Mám rád snowbording, hory, cestování a přes 20 let chovám několik druhů papoušků.",
      procKandiduji: "Politika příliš lidi nezajímá, také bych si raději jen tak užíval života, ale nejsem lhostejný a jako aktivní člověk chci dát svoji energii do zlepšení života v Hořicích. Politika je o porozumění, toleranci a kompromisech. Jen tímto stylem komunikace můžeme dosáhnout větších cílů.",
      coNabizim: "Pořádání různých kulturních akcí mě naučilo mnoho, mám zkušenosti z různých odvětví. Především marketingu a obchodu. Za více než 15 let praxe ve Sportovním zařízení Hořice mám dobrý přehled o stavu a provozu hořických sportovišť a dětských hřišť. Chtěl bych se angažovat v kultuře, propagaci města a cestovním ruchu.",
      coZmenit: "Hořice jsou skvělé místo pro život a jsou mojí srdcovou záležitostí, a proto mě mrzí, že zde ubývá počet obyvatel a klesá životní úroveň. Chtěl bych zlepšit komunikaci radnice s občany. Podporovat nové projekty a rozvoj města. Dát mladým lidem možnost se rozvíjet a seberealizovat. Hořice jsou městem tradic, ale i ty je třeba opečovávat a udržovat při životě. Mezinárodní sochařské sympozium vytváří unikátní díla, která nám zdobí Hořice a okolí. Proto máme obrovský potenciál přilákat turisty i umělce nejen z České republiky. Máme štěstí, že zde máme několik velkých kulturních a sportovních akcí za rok, které přilákají do Hořic tisíce návštěvníků, kteří podporují místní podnikatele a živnostníky. Proto by město mělo tyto akce maximálně podporovat. Protože hlavně díky těmto aktivitám vědí lidé, kde jsou na mapě Hořice.",
      motto: ""
    },
    {
      id: 9,
      titul: "",
      jmeno: 'Veronika Víšková',
      vek: 32,
      povolani: 'Asistentka obchodu, chovatelka koní',
      imageSrc: 'kandidati/VeronikaViskova.webp',
      predstaveni: "ZNÁTE Z TV 🔥 Na devítce u nás kandiduje sympatická mladá dáma od koní Veronika Víšková. Tu můžete znát i ze svých televizních obrazovek, protože hrála v několika seriálech pro Českou televizi, TV Prima nebo Barrandov, a to v seriálech: Velmi křehké vztahy, Nebezpečné vztahy,  Policajti z centra, Policie v akci, Záchranáři, Soudkyně Barbara, Soudce Alexandr, nebo Modrý kód. Na naší kandidátce má své místo především pro svůj zápal pro jezdectví a pomoc v oblasti sociálních služeb.",
      kdoJsem: "Jsem celoživotní koňák a Hořičák, takže hořická zemědělka byla po základní škole jasná volba. Studovala jsem agropodnikání. A v oboru jsem zůstala. V tuto chvíli mám na starosti prodejnu a e-shop s jezdeckými potřebami. Závodila jsem v disciplíně parkur a s koňmi  vystupuji na různých  jezdeckých akcích. Pro klienty z hořických domů sociálních služeb organizuji setkání s koňmi, kde si koně mohou nejen pohladit ale i pod dohledem nakrmit nějakou dobrotou. Loni se nám v Hořicích podařilo zorganizovat Mikuláše na koních.",
      procKandiduji: "V Hořicích žiji celý život takže vidím jak se město mění. Něco se změnilo k lepšímu něco naopak k horšímu. Ovšem z domu od stolu to jen těžko změním.",
      coNabizim: "Tato slova mi od politiků vždy znějí jako klišé a nemám je ráda. Nabízím jen energii a chuť mladého člověka který chce něco změnit. Koně mě naučili po každém pádu na zem se zase zvednout a zkoušet to znovu.",
      coZmenit: "Nejvíc mě trápí problematika v bydlení kterou jsme s manželem okusili na vlastní kůži. Ačkoliv oba dojíždíme za prací do jiných měst, i nadále jsme chtěli zůstat v Hořicích. Po čtyřech letech optimistického hledání jsme hledání vhodného pozemku zde v Hořicích vzdali protože je to pro mladé lidi nemožné. Vzdávají to i naši přátelé a členové rodiny, kteří se stěhují z Hořic a je to velká škoda. Mimo to bych chtěla podpořit rozvoj hipoterapie (rehabilitace na koni), která se v Hořicích na jízdárně začala provozovat pro děti z hendikepem. V dnešní době je chov koní velice časově a finančně  náročný a mnoho klubů a stájí se kvůli tomu ruší. Proto mě těší, že se najdou lidé kteří se po práci stále koním věnují a jsou schopni nabídnout jezdectví i veřejnosti.",
      motto: "Člověk, který nikdy nedělá chyby, je člověk, který nikdy nedělá nic."
    },
    {
      id: 10,
      titul: "",
      jmeno: 'Dita Pourová',
      vek: 48,
      povolani: 'Inspektorka kvality',
      imageSrc: 'kandidati/DitaPourova.webp',
      predstaveni: "Další ženou na naší kandidátce je Dita Pourová. Dita je pro mnoho lidí už řadu let spjatá s vrchem Gothardem, kde se společně se svým manželem bezvadně starají o chod fotbalového stadionu a kde Dita měla a má na starosti především tamní restauraci.",
      kdoJsem: "Už 48 let jsem místní hrdá patriotka. Hořice jsou pro mě nejen rodným městem, ale také místem, bez kterého si svůj život snad ani neumím představit. Domek jsme si s manželem postavili v Dobré Vodě, odkud pochází on, ale stejně nás to dál táhlo a táhne do Hořic. I proto většinu času stále trávíme na Gothardě, kde máme na starosti fotbalový areál a mě těší, že je to místo, kam pravidelně a rádi Hořičáci všech generací od nejmenších dětí chodí jak za sportem, tak i za zábavou. Trvá to už 25 let, vychovali jsme zde naše dva syny a nyní sem za námi často jezdí i naši dva vnukové. Už skoro rok na zrekonstruovaný stadion, kde dostala prostor i nová restaurace, v níž se mohu mnohem lépe realizovat v gastronomii, ke které jsem měla vždycky blízko. Blízko mám také k malování. Proto jsem se léta pracovně věnovala malování na vánoční ozdoby. Jak se říká, najdi si práci, kterou miluješ, a nikdy nebudeš muset pracovat. Další mou vášní jsou psi. S manželem léta spolupracujeme s nadací Berňáci v nouzi a těm ohroženým v rámci svých sil pomáháme. Oni nám na oplátku pomáhají hlídat stadion.",
      procKandiduji: "Politika mě nikdy moc nezajímala. I když hlavně o té komunální jsem si toho na Gothardě vyslechla vždycky dost. Nad nabídkou kandidovat s Hořickým STANem jsem dlouho váhala. Jak jsem se rozhodla, je asi zřejmé. Přišel čas na to, aby se člověk pokusil pomoct změnit k lepšímu věci, které mu v našem krásném městě ne tak úplně sedí.",
      coNabizim: "Znalost hořického prostředí, znalost lidí, jejich potřeb a přání. Toho, co se jim v Hořicích líbí, nebo naopak nelíbí. To první, ale i to druhé ráda řeknu nahlas a mám chuť se podílet na změně k lepšímu.",
      coZmenit: "V Hořicích se nepochybně podařila spousta dobrých věcí. Myslím si ale, že aby si Hořice mohly odškrtnout, že dělají maximum, je potřeba zabrat víc. Hlavně v oblastech, na které jsme se soustředili méně. Ať už se bavíme o úsporných energetických řešeních, nebo prosté komunikaci toho, co město dělá, směrem ven, určitě je na čem pracovat. Přála bych si také, aby se vedle nových projektů věnovala daleko větší péče starším, přesto důležitým objektům ve městě. Takových je spousta, ale bohužel řada školských, kulturních i sportovních zařízení potřebují více péče tak, aby Hořice byly město pro všechny.",
      motto: "Nikdy není tak dobře, aby nemohlo být ještě líp."
    },
    {
      id: 11,
      titul: "",
      jmeno: 'Tomáš Mates',
      vek: 50,
      povolani: 'Grafik, kurátor',
      imageSrc: 'kandidati/TomasMates.webp',
      predstaveni: "Druhou desítku našich kandidátů otevírá Tomáš Mates, člověk s nehasnoucím celoživotním zápalem pro kulturu a krásná umění. Vždy slušný a vlídně působící galerista s ambicí pomoci rodnému městu v oblasti, které rozumí nejlépe.",
      kdoJsem: "V Hořicích jsem se narodil a nastálo zde žiju od roku 1986. Více než 20 let jsem pracoval jako kurátor hořické Galerie plastik a po odchodu z této instituce zůstávám stále ve stejné „branži“, jen s tím rozdílem, že se nyní pohybuji ve sféře privátní. Mám na starost malou galerii v centru hlavního města a zároveň se věnuji grafickému designu.",
      procKandiduji: "Ač jsem člověk primárně apolitický, vždy jsem se snažil zajímat o dění kolem sebe. To, co se momentálně děje v mém rodném městě, a to nejen v oblasti kultury, mne mírně řečeno znepokojuje. Kandiduji, protože bych se rád zasadil o celkové zlepšení kvality života v našem krásném městě.",
      coNabizim: "Nabízím zkušenosti z více než dvacetileté praxe v oblasti v kultury. Především výtvarného umění, ale zkušenosti mám také s dramaturgií a organizováním celkem sedmi ročníků Mezinárodního festivalu krásných umění v Hořicích.",
      coZmenit: "Rád bych Hořice pomohl vrátit na výsluní významných kulturních měst nejen v rámci oblasti Podkrkonoší, ale i na úroveň celorepublikovou, kam nepochybně Hořice i z historického hlediska patřily a stále patří. Byl bych velmi potěšen, kdyby se do naší galerie a muzea vrátily například příměstské tábory pro děti a my tak mohli vychovávat mladou generaci k většímu zájmu o umění.",
      motto: "„Buď dobrým člověkem.“ Paul Walker"
    },
    {
      id: 12,
      titul: "",
      jmeno: 'Karolína Pištěková',
      vek: 28,
      povolani: 'Učitelka jógy, organizátorka Divine festivalu',
      imageSrc: 'kandidati/KarolinaPistekova.webp',
      predstaveni: "Pokračujeme v představování kandidátů a dnes se poprvé podíváme do kategorie \"pod třicet\". Pod číslem 12 na naší kandidátce najdete milou mladou dámu Karolínu Pištěkovou, která nešetří úsměvy a dobrou náladu rozdává na potkání. Její nejvýraznější hořickou stopou z poslední doby je festival jógy s názem Divine festival, jehož druhý ročník se shodou okolností koná během nadcházejícího víkendu v Areálu Zahrada v Libonicích 🧘‍♀️ 🙏",
      kdoJsem: "Jsem učitelka jógy a školitelka budoucích učitelů jógy, tanečnice s ohněm, zakladatelka Divine festivalu. Nově také vyvíjím zdravé tyčinky pro ženy. Zajímám se o zdravý životní styl ve všech podobách. Až zanedlouho vyjde autobiografická kniha, kterou jsem napsala, budu tak trochu také spisovatelkou.",
      procKandiduji: "I když jsem procestovala půlku světa a leckde málem zahnízdila, uvědomila jsem si, že Hořice mám hluboko v srdci, protože jsem zde vyrostla a znám to to tu jako vlastní boty.\nPro nápad kandidovat ve volbách jsem se po prvotních pochybnostech nadchla a moje zvědavost a touha učit se novým věcem zvítězila. Pracovat na zlepšování chodu města by mě moc bavilo. Vím, že když se pro něco nadchnu, jedu na 100 %.",
      coNabizim: "Myslím si, že by hořické radnici prospělo, kdyby tam věci probíhaly ve větším klidu a pohodovější atmosféře. Aby mohly projekty krásně plynout a růst a nic se nedělalo na sílu. Možná to není úplně typický příslib, ale nabízím to, co umím nejlépe.",
      coZmenit: "Ráda bych pozvedla především oblast kultury. Je to tu nádherné a plné potenciálu, sama zde pořádám jógový festival, který se koná vždy v srpnu. Jako nevyhnutelné vidím také rozšiřování nových možností bydlení, aby se město mohlo rozrůst o nové rodiny a nastupující generace, tj. moji vrstevníci, zde měla do budoucna perspektivu.",
      motto: "Každé otevřené srdce, otevírá další srdce."
    },
    {
      id: 13,
      titul: "",
      jmeno: 'Miloš Balihar',
      vek: 49,
      povolani: 'Podnikatel, provozovatel restaurace',
      imageSrc: 'kandidati/MilosBalihar.webp',
      predstaveni: "Dnes vám ještě představíme dalšího kandidáta z našeho seznamu, kterým je Miloš Balihar!",
      kdoJsem: "Narodil jsem se v květnu 1973 v Hořicích a žiji zde pořád. Již 25 let jsem podruhé ženatý, mám dvě děti a dvě vnoučata. Živím se hostinskou činností (mám v pronájmu dva penziony v Orlických horách) a zajišťováním občerstvení na různých společenských akcích (na závodech, festivalech apod.). V Hořicích bych chtěl více pracovních příležitostí.",
      procKandiduji: "V politice jsem se neangažoval. Nabídku podpořit nový subjekt v Hořicích jsem však bez většího váhání přijal. Své spolukandidáty jsem poznal jako zajímavou partu všech věkových kategorií a s pestrým profesním složením. Chápu nás jako kandidátku činorodých lidí, kteří se v Hořicích chtějí zasadit o svobodnější a otevřenější atmosféru nejen k vlastním aktivitám, ale aktivitám všech občanů.",
      coNabizim: "Pocházím z prostředí, kde co si člověk tvrdě neodpracuje, to nemá. Znám potřeby drobných živnostníků a vím, za kolik je chleba.",
      coZmenit: "V Hořicích bych rád viděl více pracovních příležitostí. Všímám si, že po tom místní obyvatelé hodně volají a nedivím se jim. Chtěl bych, aby Hořice naplno využívaly svého potenciálu krásného města s ideální polohou.",
      motto: "Kdo chce, hledá způsob. Kdo nechce, hledá důvod."
    },
    {
      id: 14,
      titul: "",
      jmeno: 'Ruben Richtermoc',
      vek: 42,
      povolani: 'Restaurátor, kameník',
      imageSrc: 'kandidati/RubenRichtermoc.webp',
      predstaveni: "",
      kdoJsem: "",
      procKandiduji: "",
      coNabizim: "",
      coZmenit: "",
      motto: ""
    },
    {
      id: 15,
      titul: "Ing. ",
      jmeno: 'Jakub Janák',
      vek: 35,
      povolani: 'Technolog v chemické výrobě',
      imageSrc: 'kandidati/JakubJanak.webp',
      predstaveni: "S číslem patnáct u nás kandiduje usměvavý chemik jménem Jakub Janák. Jeden z těch, kteří se domů do Hořic po studiích zvládli vrátit a založit zde rodinu. Kuba je horolezec zvyklý na potřebu jasného rozhodování. Ostatně, i na nabídku kandidatury odpověděl rozhodné chlapské ano, namísto obvyklého nevímmožná 🙃 Ne že by ho tolik lákala politika. To spíš občanská odpovědnost za město, ve kterém žijeme.",
      kdoJsem: "Žiju v Hořicích skoro celý život. Po maturitě na zdejším gymnáziu jsem vystudoval Vysokou školu chemicko-technologickou v Praze. Jako čerstvý materiálový inženýr jsem po škole nastoupil do jaroměřské výrobní firmy na pozici technologa. Rok jsem pracoval jako procesní inženýr v chemické projekci a v tuto chvíli jsem zodpovědný za celou technologii ve firmě vyrábějící speciální plasty. Jsem ženatý, mám dvě děti a volný čas rád trávím s rodinou ideálně venku, v přírodě, na horách i ve skalách.",
      procKandiduji: "Když jsem si před lety bral hypotéku na starší hořický dům, abych měl kde bydlet s mladou rodinou, došlo mi, že je to s těmi Hořicemi srdcová záležitost. Svou kandidaturu chápu jako přihlášení se k odpovědnosti za budoucí směřování města.",
      coNabizim: "Rád bych se stal zástupcem mladých rodin, rád bych, aby se ve městě dobře uchopil koncept kvalitního trávení volného času s dětmi. Líbilo by se mi, kdyby se Hořičáci těšili na víkend ve svém městě a nemuseli z něj ujíždět za zážitky jinam.",
      coZmenit: "Jsem hrdý na svoje město a rád tu vychovávám svoje děti. Hořice chápu jako rezidentní město, které by mělo být natolik dobře vybaveno jak službami, tak zázemím, aby se tu lidem, kteří pracují v okolních větších městech, chtělo žít a utrácet. Aby se tu podnikatelským subjektům chtělo investovat. Abychom všichni mohli hrdě zvát svoje kolegy, známé a kamarády na návštěvu Města nejen kamenné krásy. Abychom měli možnost s nimi zajít do výborné restaurace či kavárny, abychom je mohli ubytovat v pěkném hotelu, abychom mohli s dětmi i bez nich zažít v Hořicích krásný den. Za třicet let je znát, že město se posouvá správným směrem, přesto mám pocit, že tu chybí alternativní názor, který by měl nynějšímu vedení ukazovat i jiné možnosti a směry. Mrzí mě, když si při návštěvě nejen okolních měst podobné velikosti musím říct: „proč tohle v Hořicích nemáme“.",
      motto: "Kdo nepadá, leze pod své možnosti"
    },
    {
      id: 16,
      titul: "",
      jmeno: 'Ivo Berky',
      vek: 52,
      povolani: 'OSVČ, bývalý dlouholetý starosta obce',
      imageSrc: 'kandidati/IvoBerky.webp',
      predstaveni: "Jestliže je hnutí STAN zkratkou pro starosty a nezávislé, všichni jsme nezávislí, ale Ivo Berky je tím starostou. Je nám velkou ctí, že naše řady doplnil člověk, který v Hořicích sice nebydlí příliš dlouho, zato přináší cenné zkušenosti se starostováním. Přečtěte si jeho starostovský medailonek:",
      kdoJsem: "Do Hořic jsem se přistěhoval z Lanškrounska před čtyřmi lety. Tam jsem také působil jako uvolněný starosta obce celé dvě volební období. Nyní jsem obchodní zástupce úspěšné firmy GMS velkoobchod s.r.o., která získala 1. místo v soutěži Ocenění českých lídrů 2020 v Pardubickém kraji. Jednání s lidmi není jednoduché, ale tato práce mě naplňuje a mám ji rád.",
      procKandiduji: "I když tu ještě neznám tolik lidí jako ostatní, při sestavování kandidátky mě oslovila má kamarádka Helena Hyšková, a já jsem její nabídku velice rád přijal. V Hořicích se mi líbí, krajina je tu opravdu krásná. Nikdy mi nebylo lhostejné dění tam, kde bydlím, a rád bych se zapojil do rozkvětu tohoto města. V zastupitelstvu a radě města nikdy nebude jedna strana, a to je samozřejmě v pořádku. O to důležitější pak je, aby všichni ctili názory těch druhých, a dokázali spolu jednat u jednoho stolu ve prospěch všech.",
      coNabizim: "Mám zkušenosti s vedením obce z pozice starosty a úředníka v jedné osobě, protože na většině obcí to ani jinak nejde. Vím, jak je obtížné být nestranným a rozhodným.\n„Není člověk ten, který se zavděčí lidem všem“. Důležité je lidem naslouchat, a hlavně je pochopit. Využít každé správné příležitosti pro rozvoj města a okolních obcí. Mým koníčkem je již léta muzika, proto si myslím, že mohu přispět svými zkušenostmi i do oblasti kultury.",
      coZmenit: "Není těžké si nevšimnout, jak málo firem a pracovních možností je v Hořicích oproti jiným regionům. Lidé jdou tam, kde je pro ně dostatek práce. Pokud chceme, aby se město rozvíjelo, je zapotřebí připravovat půdu pro nové pracovní příležitosti a umožnit firmám budovat nové zázemí. I některé ulice a veřejné prostory by si zasloužily více krásy a účelovosti. Prostě pořád je co zlepšovat. Dotačních titulů je mnoho a lze je včas a správně využít.",
      motto: "Kdo „chce“, může víc než ten, kdo „může“"
    },
    {
      id: 17,
      titul: "",
      jmeno: 'David Valášek',
      vek: 35,
      povolani: 'Provozovatel hořické dopravní zdravotní služby',
      imageSrc: 'kandidati/DavidValasek.webp',
      predstaveni: "Náš kandidát David Valášek je praktický člověk, který si umí poradit s ledasčím. Profesně začal v oblasti strojírenství. Tyto dovednosti spojil s celoživotní vášní pro auta a dnes provozuje hořickou sanitní službu, kterou jistě všichni znáte. Přímý a upřímný chlap, který si nepotrpí na líbivé řeči, protože ho zajímají konkrétní činy. Přečtěte si jeho kandidátský medailonek:",
      kdoJsem: "Jmenuji se David Valášek, jsem Hořičák, je mi 35 let. Provozuji hořickou dopravní zdravotní službu, kterou předtím provozoval můj táta. Jinými slovy, když v Hořicích potkáte sanitní vůz, který není žlutý, je s velkou pravděpodobností náš. Vozíme převážně pacienty z Hořic a okolí do ambulancí a zpět, buď v rámci Hořic nebo do nemocnic v celé České republice. Jsem ženatý, mám dvě krásné dcery.\nV práci i ve volném čase mě často najdete ležet v dílně pod autem. ",
      procKandiduji: "Nabídli mi to a já nakonec řekl ano. Prý znám Hořice jako své boty, celé dny se bavím s místními, znám jejich problémy, živím se tím, že jim pomáhám, že prý znám chod řady hořických institucí, a tak bych se na to hodil. Možná na tom něco bude.",
      coNabizim: "Že v případě svého zvolení přistoupím ke svému mandátu stejně zodpovědně jako k veškeré své ostatní práci.",
      coZmenit: "Rád bych, aby se v Hořicích dobře vyrůstalo a žilo, ale to asi každý. Nejdůležitější je pro mě rozvoj hořických zdravotních služeb.",
      motto: "Co není ihned, je pozdě."
    },
    {
      id: 18,
      titul: "",
      jmeno: 'Ondřej Hlušička',
      vek: 24,
      povolani: 'Referent územního plánování',
      imageSrc: 'kandidati/OndrejHlusicka.webp',
      predstaveni: "Ačkoli to z jeho příjmení není zřejmé, jeden z našich nejmladších kandidátů Ondra Hlušička je syn naší trojky Heleny Hyškové. Nejedná se však o žádné „nemáme tam koho dát, tak tam napíšeme tebe.“ Ondra už nějakou dobu pracuje v nedaleké Jaroměři jako referent na tamním odboru územního plánování, což je pro komunální politiku velmi praktická kvalifikace. Přečtěte si jeho kandidátský medailonek:",
      kdoJsem: "Jmenuji se Ondřej Hlušička, je mi 23 let, narodil jsem se v Jičíně, avšak celý svůj život jsem prožil v Hořicích. Po studiu na základní škole Na Habru, jsem absolvoval čtyři roky na gymnáziu, SOŠ, SOU a VOŠ Hořice a dále čtyři roky na Hořickém gymnáziu. V současné době pracuji na Městském úřadě v Jaroměři na odboru výstavby, orgánu územního plánování a současně studuji na České zemědělské univerzitě v Praze na Fakultě životního prostředí, obor Územní plánování. Ve volném čase žiju muzikou, hraju v kapele Větrací šachta a zároveň spoluorganizuji občasná setkávání nejen s naší muzikou.",
      procKandiduji: "Nad nabídkou kandidovat s Hořickým STANem jsem váhal jen chvilku. Jestli chce člověk změnit věci pro budoucnost nejen svých vrstevníků ve městě, ve kterém žije a má ho rád, mělo by se začít chtít se spolupodílet ve veřejném dění.",
      coNabizim: "Své pracovní zkušenosti v oboru územního plánování, pro který jsem se nadchnul, poctivou práci a smysl pro společnou věc.",
      coZmenit: "Své pracovní zkušenosti v oboru územního plánování, pro který jsem se nadchnul, poctivou práci a smysl pro společnou věc.",
      motto: "Rád bych zatáhnul do dění ve městě víc mladých lidí, protože Hořice jsou také naše město a chceme-li v něm žít, měli bychom dbát o jeho budoucí směřování."
    },
    {
      id: 19,
      titul: "",
      jmeno: 'Denisa Lhotová',
      vek: 27,
      povolani: 'Podnikatelka',
      imageSrc: 'kandidati/DenisaLhotova.webp',
      predstaveni: "Na naší kandidátce najdete mladou, cílevědomou, dobrosrdečnou dámu s kérkami na rukou a vlasy skoro po pás. Je jí sedmadvacet a jmenuje se Denisa Lhotová. 🤿 Někdejší stříbrnou medailistku z prestižní potápěčské soutěže v Indonesii najdete na 19. pozici.",
      kdoJsem: "Jsem rodilá Hořičačka. Odmaturovala jsem na prvním soukromém jazykovém gymnáziu v Hradci Králové. Od vysokoškolského studia informatiky mě svedl můj úspěch v mezinárodní potápěčské soutěži na Bali, kde jsem pak 2 roky působila jako průvodkyně pod vodou i nad vodou a organizovala jsem vlastní poznávací zájezdy po Indonésii. Co jsem zpět v Čechách, podnikám. Mám vlastní značku na ručně dělané šperky a také lifestyle značku zaměřující se na vodu, zuby a vůně. Obojí spojuje prvotřídní kvalita produktů a důraz na souznění s planetou Zemí a veškerým životem na ní. Ve volném čase poznávám svět a věnuji se charitativní činnosti. Za největší úspěch v rámci pomoci potřebným považuji dar ve výši již přes 1 000 000 Kč pro nadační fond Kapka naděje, který jsem osobně odpracovala.",
      procKandiduji: "Aby se věci změnily, nestačí chtít změnu po druhých. Člověk má být součástí té změny. Ačkoli mě to do politiky nikdy dřív netáhlo, dostávám se do věku, kdy pro mě budoucí směřování mého rodiště začíná být velmi důležité.",
      coNabizim: "To, co umím nejlépe. Kladení vysokých, avšak reálných cílů a jejich postupné, systematické plnění. To vše ve prospěch města a jeho občanů, nikoli proti nim, jak to na mě mnohdy působí.",
      coZmenit: "Jako jednotlivec nezměním bohužel nic. Jako součást progresivního týmu bych se spolu s ostatními ráda zaměřila na 3 zásadní témata – bydlení, pracovní příležitosti & infrastruktura města.",
      motto: "Věř, běž a dokážeš."
    },
    {
      id: 20,
      titul: "",
      jmeno: 'Jana Hatašová',
      vek: 48,
      povolani: 'Majitelka a provozovatelka restaurace Dachovy',
      imageSrc: 'kandidati/JanaHatasova.webp',
      predstaveni: "",
      kdoJsem: "",
      procKandiduji: "",
      coNabizim: "",
      coZmenit: "",
      motto: ""
    },
    {
      id: 21,
      titul: "",
      jmeno: 'Dominik Kindl',
      vek: 20,
      povolani: 'Student ČVUT architektura',
      imageSrc: 'kandidati/DominikKindl.webp',
      predstaveni: "Teprve dvacetiletý Dominik Kindl je coby student architektury zapálený pro moderní trendy ve stavitelství a urbanistice. Naši kandidátku hlídá z poslední příčky, takže pokud stále přemýšlíte, komu ještě udělit preferenční křížek, s Dominikem nešlápnete vedle. Přečtěte si jeho kandidátský medailonek:",
      kdoJsem: "Je mi 20 let. Jsem studentem architektury a stavitelství na České vysokém učení technickém v Praze, takže vnímání prostoru a staveb kolem sebe je pro mě nutnost, ale zároveň i koníček.\nArchitektura je věc, která je pro mě velice zajímavá a proto vidím, jak krásné stavby se v Hořicích nacházejí a jaký potenciál v tomto směru město má.",
      procKandiduji: "Politika mě vždy bavila a fascinovala. Proto jsem neváhal a přijal pozvání na kandidátku Hořického STANu, zejména pro novou zkušenost, která mě zase naučí něco nového.",
      coNabizim: "Znalost technické stránky staveb a městského prostoru, zejména veřejného prostředí pro lidi. Velká část lidí ráda tráví čas venku v prostoru města a k tomu, aby se tam cítili dobře, je nutné něco dělat. Vytvářet prostor, který dobře funguje, je esteticky zajímavý a dopřeje člověku klid po pracovním dnu. Myslím si, že je vždy vhodný čas usilovat o zelené a architektonicky zajímavé město.",
      coZmenit: "Rád bych viděl Hořice zpět na výsluní v architektuře a mohl jít po ulici a být skutečný hrdý, na to co se zde dělá pro lidi a prostor kolem nich. Rád bych viděl, že město Hořice jde s dobou, snaží se pěstovat zeleň a vytvářet chladnější prostor v letních měsících, kvalitně zpracované komunikace a snahu města pečovat o stavby ve svém vlastnictví a tím přilákat návštěvníky, kteří doslova zůstanou stát nad krásou Hořic.",
      motto: "„Můžeš být hloupý, ale musíš si umět poradit“."
    },
  ]

export default kandidati