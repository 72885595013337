import problemy from '../Data/problemy';
import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import SerialDil from "./SerialDil"


export default function Serial() {
    return (
        <section className="bg-gray-100" id="problemy">
            <div className="max-w-7xl mx-auto pt-8 md:pt-20 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block ">Problémy, o kterých se nemluví (seriál)</span>
                    {/*<span className="block text-indigo-600">Start your free trial today.</span>*/}
                </h2>
            </div>
            <div className="mx-auto text-gray-800 px-4 py-12 sm:px-6 lg:max-w-7xl lg:px-8">

                <BrowserRouter>
                    <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                            {problemy.map((problem) =>
                            (
                                <li key={problem.id} className="mr-2" role="presentation">
                                    <NavLink className={({ isActive }) => (isActive ? "inline-block p-4 rounded-t-lg border-b-2 border-blue-500  hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 dark:border-blue-500" : "inline-block p-4 rounded-t-lg border-b-2  hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-500 dark:border-blue-500")} to={`/${problem.hrefid}`}>{problem.nazev} {problem.id !== 0 && `(${problem.id})`}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Routes>
                        {problemy.map((problem) => (
                            <Route key={problem.id} path={`/${problem.hrefid}`} element={<SerialDil problem={problem} scroll={problem.scroll} />} />
                        ))}
                    </Routes>
                </BrowserRouter>

            </div>
        </section>
    )
}