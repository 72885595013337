import React from "react";
import Page from "./Components/Page.jsx";

function App() {
  return (
      <div className="App">
        <Page>
        </Page>
</div>
  );
}

export default App;
