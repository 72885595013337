import React, { useState } from 'react';
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'

function NewLineText(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <p className="pt-1">{str}</p>);
  
  return newText;
}

export default function Kandidat(props) {
    const [open, setOpen] = useState(false);
  
    const cancelButtonRef = useRef(null);

    return (
        <>
        <div key={props.kandidat.id} onClick={() => setOpen(true)} className="group" style={{cursor: 'pointer'}}>
        <div className="shadow-lg w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
          <img
            src={`../${props.kandidat.imageSrc}`}
            alt={props.kandidat.jmeno}
            className="w-full h-full object-center object-cover group-hover:opacity-75 transition-opacity"
          />
        </div>
        <h3 className="mt-4 text-lg font-medium text-gray-900">{props.kandidat.id}. {props.kandidat.titul}{props.kandidat.jmeno} ({props.kandidat.vek})</h3>
        <p className="mt-1 text-sm text-gray-900">{props.kandidat.povolani}</p>
      </div>
      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative w-full z-20" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Představení kandidáta č. {props.kandidat.id} - {props.kandidat.titul}{props.kandidat.jmeno}
                      </Dialog.Title>
                      <div className="text-sm mt-2">
                        <NewLineText text={props.kandidat.predstaveni}></NewLineText>
                        {props.kandidat.predstaveni === "" &&
                        <p>Medailonek tohoto kandidáta pro vás připravujeme...</p>
                        }
                        {props.kandidat.kdoJsem !== "" &&
                        <>
                        <h3 className="text-md font-semibold mt-3">Kdo jsem a co dělám</h3>
                        <NewLineText text={props.kandidat.kdoJsem}></NewLineText>
                        </>
                        }

                        {props.kandidat.procKandiduji !== "" &&
                        <>
                        <h3 className="text-md font-semibold mt-3">Proč kandiduji</h3>
                        <NewLineText text={props.kandidat.procKandiduji}></NewLineText>
                        </>
                        }

                        {props.kandidat.coNabizim !== "" &&
                        <>
                        <h3 className="text-md font-semibold mt-3">Co nabízím</h3>
                        <NewLineText text={props.kandidat.coNabizim}></NewLineText>
                        </>
                        }

                        {props.kandidat.coZmenit !== "" &&
                        <>
                        <h3 className="text-md font-semibold mt-3">Co chci změnit</h3>
                        <NewLineText text={props.kandidat.coZmenit}></NewLineText>
                        </>
                        }

                        {props.kandidat.motto !== "" &&
                        <>
                        <h3 className="text-md font-semibold mt-3">Motto</h3>
                        <NewLineText text={props.kandidat.motto}></NewLineText>
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shadow-md bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Zavřít
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
      </>
    )
}