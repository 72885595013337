import React from 'react';

export default function SocSite() {

    return (
        <section className="bg-white" id="socialni-site">
            <div className="max-w-7xl mx-auto pt-8 md:pt-20 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block">Novinky ze sociálních sítí</span>
                    {/*<span className="block text-indigo-600">Start your free trial today.</span>*/}
                </h2>
            </div>
            <div className="mx-auto text-gray-800 px-4 py-12 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="fb-page" data-href="https://www.facebook.com/HorickySTAN" data-tabs="timeline" data-width="500" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/HorickySTAN" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/HorickySTAN">Hořický STAN</a></blockquote></div>
            <div className="pb-6"></div>    
            </div>
        </section>
    )
}