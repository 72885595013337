export default function ONas() {
    return (
        <section className="bg-gray-100" id="o-nas">
            <div className="max-w-7xl mx-auto pt-8 md:pt-20 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block ">O nás</span>
                    {/*<span className="block text-indigo-600">Start your free trial today.</span>*/}
                </h2>
            </div>
            <div className="mx-auto text-gray-800 px-4 py-12 sm:px-6 lg:max-w-7xl lg:px-8">
                <p className="pb-4">Hořický STAN je skupina zkušených nezávislých odborníků z řady oblastí – naším cílem je mít kandidátku co nejpestřejší, abychom na nic důležitého nezapomněli. Díky vstřícnému přístupu krajského vedení Starostů a nezávislých jsme se dohodli na tom, že v Hořicích budeme kandidovat pod jejich záštitou. Mezi hlavní cíle od prvopočátku existence STAN od jeho vzniku patří efektivní správa měst a obcí, služba občanům a snaha spojovat lidi, a ne je rozdělovat.</p>
                <p className="pb-4">Naším prvním krokem bude radikální změna způsobu komunikace města s občany, organizacemi a podnikatelskými subjekty v Hořicích. Město nemá být arogantní "vrchností", ale má sloužit svým občanům, otevřeně je informovat a snažit se vycházet vstříc jejich potřebám.</p>
                <p>Naší hlavní prioritou je zastavit „vylidňování“ Hořic. Víte o tom, že za posledních 30 let se počet obyvatel Hořic snížil o více než 1.000 lidí? Tento nepříznivý trend podle nás souvisí s řadou dosud uspokojivě neřešených a navzájem provázaných oblastí.</p>
            </div>
        </section>
    )
}