import React from "react";
import { Table, Card } from "flowbite-react"
import { useEffect } from "react";
import ReactMarkdown from 'react-markdown'

export default function SerialDil(props) {
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );


    useEffect(() => {
        const click = async () => {
            await delay(50)
            document.getElementById("prob").click()
        }
        if (props.scroll === true) {
            click()
        }
    }, [props.scroll])

    return (
        <div>
            <h3 className="text-xl pt-3 font-bold mb-5">{props.problem.id !== 0 && `${props.problem.id}) `}{props.problem.nazev}</h3>
            <p><strong>{props.problem.nadpis}</strong></p>
            {props.problem.odstavce.map((odstavec, id) => 
            (
                <div key={odstavec.id}>
                    {odstavec.typ === 0 &&
                        <div className="pt-5">
                            <ReactMarkdown>{odstavec.text}</ReactMarkdown>
                        </div>
                    }
                    {odstavec.typ === 1 &&
                        <div className="pt-5 md:max-w-md">
                            <Card>
                                <img src={`../${odstavec.src}`} alt={odstavec.alt} />
                            </Card>
                        </div>
                    }
                    {odstavec.typ === 2 &&
                        <div className="pt-5">
                            <Table>
                                <Table.Body className="divide-y">
                                    {odstavec.tr.map((tr, id) => (
                                        <Table.Row key={id} className="bg-white dark:border-gray-700 dark:bg-gray-800 ">
                                            {tr.td.map((td, id) => (
                                                <Table.Cell key={id} className={td.b ? "text-gray-900 dark:text-white font-semibold" : ""}>{td.data}</Table.Cell>
                                            ))}
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                    }
                    {odstavec.typ === 3 &&
                    
                        <div className={`pt-5 ${odstavec.md ? "max-w-md" : "max-w-4xl"}`}>
                            <Card>
                                <img src={`../${odstavec.src}`} alt="tabulka" />
                            </Card>
                        </div>
                    }
                </div>
            ))}
        </div>

    )
}
