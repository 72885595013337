import Kandidat from './Kandidat'
import kandidati from '../Data/kandidati'

export default function Kadidati() {
  return (
      <section className="bg-white" id="kandidati"> {/*bg-gray-100*/}
      <div className="max-w-7xl mx-auto pt-8 md:pt-20 px-4 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Naši kandidáti</span>
            {/*<span className="block text-indigo-600">Start your free trial today.</span>*/}
          </h2>
        </div>
      <div className="max-w-2xl mx-auto px-4 lg:max-w-7xl lg:px-8">


        <div className="grid grid-cols-1 py-12 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
        {kandidati.map((kandidat) => (
            <Kandidat key={kandidat.id} kandidat={kandidat}></Kandidat>
          ))}
        </div>

      </div>
    </section>
  )
}