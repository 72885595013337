import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Kandidati from "./Kadidati"
import ONas from "./ONas"
import SocSite from './SocSite'
import Serial from "./Serial"

const navigation = [
  { name: 'Úvod', href: '#' },
  { name: 'O nás', href: '#o-nas' },
  // { name: 'Volební program', href: '#volebni-program' },
  { name: 'Kandidáti', href: '#kandidati' },
  // { name: 'O volbách', href: '#o-volbach' },
  { name: 'Problémy, o kterých se nemluví', href: '#problemy', id: "prob" },
  { name: 'Sociální sítě', href: '#socialni-site' },
  { name: 'Volební noviny', href: '/noviny.pdf', blank: true },
]

export default function Page() {

  return (
    <>
      <div className="relative bg-white overflow-hidden scroll-smoth">
        <div className="z-10 fixed py-10 bg-white w-full shadow-lg"></div>
        <div className="max-w-7xl mx-auto">
          <Popover>
            <div className="fixed bg-white w-full pb-5 pt-5 px-5 z-20">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full lg:w-auto">
                    <a className="flex items-center" href="/">
                      <span className="sr-only">Hořický stan</span>
                      <img
                        alt="Hořický stan"
                        className="max-w-7xl h-8 sm:h-10"
                        src="../logo.webp"
                      />
                      {/* <span class="self-center text-xl font-semibold whitespace-nowrap">Hořický stan</span> */}
                    </a>
                    <div className="-mr-2 flex items-center lg:hidden">
                      <Popover.Button className="bg-white z-1O rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Otevřit menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block lg:ml-10 lg:pr-4 lg:space-x-8">
                  {navigation.map((item) => (
                    <a key={item.name} id={item.id} href={item.href} target={item.blank && "_blank"}  className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="fixed z-20 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="../logo.webp"
                        alt="Hořický stan"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Zavřít menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <Popover.Button key={item.name} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
                        <a href={item.href}>{item.name}</a>
                      </Popover.Button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          {/* <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
            <main className="mx-auto pt-16 max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl pt-20 tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">Hořický STAN</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                </p>
              </div>
            </main>
          </div> */}
          {/* <h1 className="text-4xl pt-10 tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Hořický STAN</span>
          </h1> */}
          <div className="pt-14">
            <img
              className="h-67 w-full object-cover lg:h-full"
              src="../uvod-2000.webp"
              srcSet='../uvod-600.webp 600w, ../uvod-1024.webp 1024w, ../uvod-1600.webp 1600w, ../uvod-2000.webp 2000w'
              alt="Společná fotka všech kandidátů"
            />
          </div>
        </div>

      </div>

      <ONas></ONas>
      {/* <VolebniProgram></VolebniProgram> */}
      <Kandidati></Kandidati>
      {/* <OVolbach></OVolbach> */}
      <Serial></Serial>
      <SocSite></SocSite>
      {/* <PdfReader></PdfReader> */}
      <div className="bg-gray-800" id="footer">
        <div className="text-gray-100 py-5">
          <p className="text-center">Hořický STAN 2022</p>
        </div>
      </div>
    </>
  )
}